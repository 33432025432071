import React, { useState, useEffect } from 'react';

const DynamicComponentLoader = ({ config, item_data }) => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    // Reset component state when config changes
    setComponent(null); 

    if (config && config.component) {
      // Dynamic import based on config
      import(`../${config.component}`)
        .then(comp => {
          setComponent(() => comp.default); // Update state with the imported component
        })
        .catch(error => {
          console.error(`Could not load component at: ../${config.component}`, error);
        });
    }
  }, [config]); // Dependency array includes config

  if (!Component) {
    return <div>Loading... Component {config?.component}</div>;
  }

  return <Component item_data={item_data} />;
};

export default DynamicComponentLoader;
