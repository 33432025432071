import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    Box,
    Card,
    CardContent,
    Grid,
    List,
    Paper,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Tooltip,
  } from "@mui/material";
import { loadItem } from '../../api'; // Ensure this is the correct import path
import Loading from '../Loading/Loading';
import useModelLoader from '../../hooks/useModelLoader';
import { usePermissions } from "../../contexts/PermissionsContext";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { getDisplayValue } from "../../utils/utils";
import { useSelectOptions } from "../../hooks/useSelectOptions";

// Accepts section and id as props
const FilePreview = ({ section, id }) => {

  const { data: item_data, isLoading, isError, error } = useQuery({
    queryKey: ['filePreview', section, id],
    queryFn: () => loadItem(section, id),
    staleTime: Infinity, // Data never becomes stale
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours cache time
  });


  const { modelData } = useModelLoader(section);



  const { permissions } = usePermissions();
  const selectMappings = useSelectOptions(modelData?.fields);


  const groupFieldsByCardOrganization = (fields) => {
    const groupedFields = {};

    fields.forEach((field) => {
      const cardOrg = field.cardOrganization || "Main Info";
      if (groupedFields[cardOrg]) {
        groupedFields[cardOrg].push(field);
      } else {
        groupedFields[cardOrg] = [field];
      }
    });

    return groupedFields;
  };

  let canModify = false;

  if (modelData?.permissions?.permissions_to_modify) {
    canModify = permissions.includes(`can_modify_${section}`);
  } else {
    canModify = true;
  }


  if (isLoading || !modelData   ) return <Loading />;
  if (isError) return <div>Error loading file: {error.message}</div>;


  return (
    <>
    <img src={`https://cb-business-manager${process.env.REACT_APP_S3_REGION_URL}${section}/${id}/main_image/${item_data["main_image"]}-450-300.jpg`}/>

    <Card sx={{}}>
  <CardContent sx={{}}>

  <Grid container spacing={2}>
  <Grid item xs={4} sm={4} md={3}>
  <ListItemText primary="Id" secondary={item_data.id || "No info"} />
  </Grid>
  <Grid item xs={6} sm={4} md={3}>
  <ListItemText primary="Slug" secondary={item_data.slug || "No info"} />
  </Grid>
  <Grid item xs={6} sm={4} md={3}>
  <ListItemText primary="Created at" secondary={item_data.created_at || "No info"} />
  </Grid>
  <Grid item xs={6} sm={4} md={3}>
  <ListItemText primary="Updated at" secondary={item_data.updated_at || "No info"} />
  </Grid>
  <Grid item xs={6} sm={4} md={3}>
  <ListItemText
          primary="Created by"
          secondary={item_data.created_by ? selectMappings.created_by?.[item_data.created_by] || "Unknown" : "No info"}
        />
  </Grid>
  <Grid item xs={6} sm={4} md={3}>
  <ListItemText
          primary="Last updated by"
          secondary={item_data.last_updated_by ? selectMappings.last_updated_by?.[item_data.last_updated_by] || "Unknown" : "No info"}
        />
  </Grid>
</Grid>
  </CardContent>
</Card>




      {Object.entries(groupFieldsByCardOrganization(modelData?.fields)).map(
        ([cardOrg, fields]) => (
          <Box key={cardOrg}>
            <Typography variant="h6" component="h2" sx={{ letterSpacing: 1, fontSize: 14}}>
              {cardOrg}
            </Typography>
            <Grid container spacing={2} key={cardOrg}>
              {fields.map((field) => {
                if (field.showInDetail) {
                  if (field.fieldType === "text") {
                    return (
                      <Paper
                        elevation={0}
                        style={{
                          border: "1px dotted lightgray", // light border
                          backgroundColor: "white", // light grey background
                          elevation: 0, // no elevation
                        }}
                        key={field.key}
                        sx={{
                          width: field.paperWidth || "100%",
                          marginLeft: 2,
                          marginTop: 4,
                          p: 5,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" component="h6">
                            {field.title.charAt(0).toUpperCase() +
                              field.title.slice(1)}
                          </Typography>

                        </Box>
                        <Typography
                          variant="body2"
                          component="p"
                          dangerouslySetInnerHTML={{
                            __html: item_data[field.key],
                          }}
                        />
                      </Paper>
                    );
                  } else {
                    // Render list item for non-'text' field types
                    if (
                      field.showInDetailConditionNull &&
                      item_data[field.key] === null
                    ) {
                      return null;
                    } else {
                      return (
                        <Grid item xs={4} key={field.key}>
                          <List sx={{ listStyleType: "none" }}>
                            <ListItem key={field.key}>
                              <ListItemText
                                style={{ textTransform: "capitalize" }}
                                primary={
                                  <span>
                                    {field.title.replace(/_/g, " ")}
                                   
                                  </span>
                                }
                                secondary={getDisplayValue(
                                  field,
                                  item_data[field.key],
                                  selectMappings,
                                  true
                                )}
                              />
                             
                            </ListItem>
                          </List>
                        </Grid>
                      );
                    }
                  } 
                }
                return null;
              })}
            </Grid>
          </Box>
        )
      )}
    </>
  );
};

export default FilePreview;
