
import React, { useState } from 'react';
import { Button, Menu, MenuItem } from "@mui/material";
import modalConfig from './ModalConfig';
import ModalWithTrigger from './ModalWithTrigger';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';



const ModalButtonsAddTo = ({ buttonNames, onClickHandle, section, handleRefresh=null, selectedRowIds }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    if (typeof onClickHandle === 'function') {
      onClickHandle();
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div>
      <Button 
      sx={{
        width: '100%',
        color: 'white',
        backgroundColor: 'primary.main',
      }}
      aria-controls="add-to-menu" 
      aria-haspopup="true" 
      endIcon={<ArrowDropDownIcon />}
      onClick={handleClick}>
        Add
      </Button>
      <Menu id="add-to-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {buttonNames.map((buttonName, index) => {
          const ModalComponent = modalConfig[buttonName]?.component;
          const label = modalConfig[buttonName]?.label;
          return (
            <MenuItem key={index} onClick={handleClose}>
              {ModalComponent && <ModalWithTrigger type={buttonName} label={label} section={section} selectedRowIds={selectedRowIds} handleRefresh={handleRefresh} modalConfig={modalConfig}/>}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ModalButtonsAddTo;

