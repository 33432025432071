import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Help({ helpData }) {
  const [dataToMap, setDataToMap] = useState();

//?${Math.floor(Math.random() * 10000)
  useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_GLOBAL_MAPPING_URL}help_texts/help_texts_component_${helpData}.json`
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            console.log(data)
            setDataToMap(data);
          } catch (error) {
            console.error("Failed to fetch data for", helpData, error);
          }
        };

        fetchData();
      }, [helpData]);



  return (
    <div>
      {dataToMap?.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}


export default Help;