// AddModal.jsx
import {
  Modal,
  Box,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { postItems, updateItem } from "../../api";
import useModelLoader from "../../hooks/useModelLoader";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AutocompleteField from "../Autocomplete/AutocompleteField";
import * as Constants from "../../constants/selectConstants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutocompleteWithQuery from "../Autocomplete/AutocompleteWithQuery";
import { unslugify } from "../../utils/utils";

dayjs.extend(utc);

function AddModal({ open, handleClose, section }) {
  const [formData, setFormData] = useState({});
  const [fieldsData, setFieldsData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [insertId, setInsertId] = useState(null);
  const [slugData, setSlugData] = useState([]);
  const [conditionnalLoadValue, setConditionnalLoadValue] = useState([]);

  //console.log("render");

  const mutation = useMutation({
    mutationFn: (data) => postItems(section, data),
    enabled: false,
    onSuccess: (data) => {
      // Handle success
      setShowSuccess(true);
      const logEntry = JSON.parse(data.body);
      const insertID = logEntry.insertID;
      setInsertId(insertID);
      if (modelData.makeSlug) {
        let slugValue = makeFinalSlug();
        slugValue = slugValue + "-" + insertID;
        mutationSlug.mutate({ id: insertID, value: slugValue });
      }
    },
    onError: (error) => {
      // Handle error
      console.error("Error posting data:", error);
    },
  });

  const mutationSlug = useMutation({
    mutationFn: (args) => updateItem(section, args.id, "slug", args.value),
    enabled: false,
    onSuccess: (data) => {
      //console.log("slug success");
    },
    onError: (error) => {
      console.error("Error posting data:", error);
    },
  });

  const makeFinalSlug = () => {
    if (modelData?.makeSlug) {
      let newSlug = modelData.makeSlug
        .map((slug) => {
          //console.log("formdata-slug", formData[slug]);
          //console.log("slugData-slug", slugData[slug]);

          // Ensuring slugToSlugify is treated as a string
          let slugToSlugify = String(slugData[slug] || formData[slug]);

            slugToSlugify = slugToSlugify.toLowerCase().trim();

            // remove accents from charaters
            slugToSlugify = slugToSlugify.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        
            // replace invalid chars with spaces
            slugToSlugify = slugToSlugify.replace(/[^a-z0-9\s-]/g, ' ').trim();
        
            // replace multiple spaces or hyphens with a single hyphen
            slugToSlugify = slugToSlugify.replace(/[\s-]+/g, '-');

          return slugToSlugify;
        })
        .join("-");

      return newSlug;
    }
  };

  const handleAdd = () => {
    mutation.mutate(formData);
  };

  const { modelData, filteredFields } = useModelLoader(section);

  useEffect(() => {
    if (filteredFields) {
      setFieldsData(filteredFields);
    }
  }, [filteredFields]);

  const handleInputChange = (key, value) => {
    console.log(key, value);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSlug = (key, value) => {
    //console.log(key, value);
    setSlugData({
      ...formData,
      [key]: [value],
    });
  };

  const handleInputDateChange = (key, value) => {
    console.log("value date:", value);
    if (value && value != "0000-00-00") {
      const formattedDate = dayjs(value).format("YYYY-MM-DD");
      console.log("Formatted date:", formattedDate);

      setFormData({
        ...formData,
        [key]: formattedDate,
      });
      //console.log(formData);
    }
  };

  const [selectOptions, setSelectOptions] = useState({});

  useEffect(() => {
    fieldsData.forEach((field) => {
      if (field.selectLocalValue) {
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          [field.key]: Constants[field.selectLocalValue],
        }));
      } else if (field.fieldType === "select" && field.selectFile) {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${
                field.selectFile
              }?${Math.floor(Math.random() * 10000)}`
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            //console.log(data);
            setSelectOptions((prevOptions) => ({
              ...prevOptions,
              [field.key]: data,
            }));
          } catch (error) {
            console.error(
              "Failed to fetch select options for",
              field.key,
              error
            );
          }
        };

        fetchData();
      }
    });
  }, [fieldsData]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="add-item-title" >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: fieldsData.length>1 ? '80vw' : '40vw',
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            paddingX: 5,
            maxHeight: "85vh", // Set a fixed maximum height
            overflow: "auto", // Add scrollbar when content overflows
          }}
        >
        {showSuccess ? (
          <div>
            <p>Data inserted successfully!</p>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                p: 1,
                m: 1,
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              <Button
                sx={{ m: 2, width: "30%" }}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                component={Link}
                to={`/${section}/detail/${insertId}`}
              >
                View Entry
              </Button>
              <Button
                sx={{ m: 2, width: "30%" }}
                onClick={() => {
                  setShowSuccess(false);
                  setFormData({});
                }}
                variant="contained"
                color="primary"
              >
                Add Another
              </Button>
              <Button
                sx={{ m: 2, width: "30%" }}
                onClick={() => {
                  setShowSuccess(false);
                }}
                variant="contained"
                color="primary"
              >
                Add Another one with same data
              </Button>
            </Box>
          </div>
        ) : (
          <>
            <h2 id="add-item-title">{modelData?.appTexts?.addAnItem || "Add Item "+unslugify(section)}</h2>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="flex-start">
            {fieldsData.map((field) => (
              <Grid item xs={12}  md={fieldsData.length>1 ? 6 : 12} key={field.key}>
                {(() => {
                  switch (field.fieldType) {
                    case "select":
                      const options = selectOptions[field.key] || [];
                      return (
                        <FormControl fullWidth margin="normal">
                          <InputLabel>{field.title}</InputLabel>
                          <Select
                            label={field.title}
                            value={formData[field.key] || ""}
                            onChange={(e) =>
                              handleInputChange(field.key, e.target.value)
                            }
                            required={field.required}
                          >
                            {options.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.n || option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    case "autocomplete":
                      return (
                        <AutocompleteField
                          title={field.title}
                          selectFile={field.selectFile}
                          selectFileKey={field.selectFileKey}
                          selectFreesolo={field.selectFreesolo}
                          conditionnalFileLoad={field.conditionnalFileLoad}
                          formData={formData}
                          onValueChange={(value) =>
                            handleInputChange(field.key, value)
                          }
                        />
                      );

                      case "autocompleteWithQuery":
                        return (
                          <AutocompleteWithQuery
                            key={field.key}
                            title={field.title}
                            selectFile={field.selectFile}
                            selectFileKey={field.selectFileKey}
                            selectFreesolo={field.selectFreesolo}
                            conditionnalFileLoad={field.conditionnalFileLoad}
                            formData={formData}
                            sendBackJson={true}
                            value={formData[field.key]}
                            onValueChange={(value) => {
                              handleInputChange(field.key, value.id);
                              handleSlug(field.key, value.name);
                            }}
                          />
                        );

                    case "input":
                      return (
                        <TextField
                          fullWidth
                          margin="normal"
                          label={field.title}
                          variant="outlined"
                          type="text"
                          value={formData[field.key] || ""}
                          onChange={(e) =>
                            handleInputChange(field.key, e.target.value)
                          }
                          required={field.required}
                        />
                      );



                      case "date":
                      return (
                        <div>
                          <DatePicker
                            format="DD/MM/YYYY"
                            sx={{ width: "100%", mt: 2 }}
                            label={field.title}
              
                            onChange={(newDate) => {
                              handleInputDateChange(field.key, newDate);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                         
                              />
                            )}
                          />
                        </div>
                      );


                      case "datetime":
                        return (
                       
                            <DateTimePicker
                     margin="normal"
                              label={field.title}
                              value={formData[field.key] || dayjs()}
                              onChange={(e) =>
                                handleInputChange(field.key, e.target.value)
                              }
                              renderInput={(params) => (
                                <TextField
                                margin="normal"
                                  {...params}
                                  fullWidth
                           
                                />
                              )}
                            />
                        
                       
                        );
                  }
                })()}
              </Grid>
            ))}
            </Grid>
            <Button
              onClick={handleAdd}
              variant="contained"
              color="primary"
              style={{ marginTop: "16px", width: '100%'}}
            >
              Add
            </Button>
          </>
        )}
      
      </Box>
      </Box>
    </Modal>
  );
}

export default AddModal;
