// src/hooks/useTranslations.js
import { useState, useEffect } from 'react';
import getTranslations from '../utils/getTranslations';

const useTranslations = (fileNames) => {
  const [allTranslation, setAllTranslation] = useState({});

  useEffect(() => {
    getTranslations(fileNames).then(setAllTranslation);
  }, [fileNames.join(',')]); // Depend on fileNames as a string to correctly react to changes

  return allTranslation;
};

export default useTranslations;
