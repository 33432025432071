
import MakeGlobalExcelFormModal from "./MakeGlobalExcelFormModal";


export const modalStyles = {
  regular: {
    position: "absolute",
    top: "5vw",
    left: "5vw",
    display: "flex",
    width: "87vw", 
    height: "80vh", 
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 10,
    overflow: 'auto',
    p: 4
  },
  large: {
    position: "absolute",
    top: "20%",
    left: "20%",
    display: "flex",
    transform: "translate(-20%, -20%)",
    width: 800, 
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: 'auto',
    p: 4
  },
};

const excelModalConfig = {
  defaultStyle: modalStyles.regular,
  global_table: {
    component: MakeGlobalExcelFormModal,
    query: true,
    label: "Make a General Excel for these records",
  }
};


export default excelModalConfig;
