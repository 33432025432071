
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from "../PDF/TestPdf";


function Home() {


    return (<div>

      Welcome to my app

      <PDFDownloadLink 
  document={<MyDocument />} 
  fileName="myfile.pdf"
  style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
>
  {({ blob, url, loading, error }) =>
    loading ? 'Loading document...' : 'Download now!'
  }
</PDFDownloadLink>

  </div>)
    ;
  }
  
  export default Home;