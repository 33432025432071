import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import { useSelectOptions } from '../../hooks/useSelectOptions';
import { Typography, IconButton, Menu, MenuItem } from "@mui/material";
import ActionButtons from "../ActionButtons/ActionButtons";
import { useImagePreview, MemoizedImagePreview } from "../../utils/imagePreview";

const GridTable = ({ data, section, modeldata, specialTable = null, handleClose, fromTable, fromId, handleRefresh, handleThisRefresh }) => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    handleMouseOver,
    handleMouseOut,
    handleMouseMove,
    showLargerImg,
    previewTop,
    previewLeft,
    previewInfo,
  } = useImagePreview();

  useEffect(() => {
    if (modeldata) {
      setIsLoading(false);
    }
  }, [modeldata]);

  const selectMappings = useSelectOptions(modeldata?.fields);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!modeldata) {
    return <div>No data available</div>;
  }

  let showInTableKey = 'showInTable';
  if (specialTable != null) {
    showInTableKey = 'showIn' + specialTable;
  }

  const columns = modeldata.fields
    .filter(field => field[showInTableKey])
    .map(field => {
      const flexValue = field.key === 'id' ? 0.3 : 1;
  
      return {
        field: field.key,
        headerName: field.title,
        flex: flexValue,
        minWidth: 50,
        renderCell: (params) => {
          let finalValue = params.value;
          if (field.fieldType === "select" && selectMappings[field.key]) {
            finalValue = selectMappings[field.key][finalValue] || finalValue;
          }
          if (field.linkInTable) {
            finalValue = <Link onClick={handleClose} to={`/${section}/detail/${params.row.id}`}>{finalValue}</Link>;
          }
          if (field.linkFkInTable) {
            const sectionToLink = field.linkFkTo || field.key.replace("_id", "");
            finalValue = <div style={{display: 'inline'}}>{finalValue} <Tooltip title={`This link will point to the ${sectionToLink} section`}><Link onClick={handleClose} to={`/${sectionToLink}/detail/${params.value}`}><LinkIcon/></Link></Tooltip></div>;
          }
          if (field.renderImg && params.value) {
            const thumbnail = `${process.env.REACT_APP_S3_BASE_IMG}${section}/${params.row.id}/main_image/${params.value}-50-40.jpg`;
                 return (
              <img 
                src={thumbnail} 
                loading="lazy"
                onMouseOver={(e) => handleMouseOver(e, section, params.row.id, params.value)}
                onMouseOut={handleMouseOut}
                onMouseMove={handleMouseMove}
              alt="Thumbnail"
              />
            );
          }
          return finalValue;
        }
      };
    });

    if (modeldata && modeldata.actionButtons && Object.keys(modeldata.actionButtons).includes(fromTable)) {
      columns.push({
          field: "actions",
          headerName: "Actions",
          flex: 0.3,
          renderCell: (params) => (
              <ActionButtons section={section} fromTable={fromTable} fromId={fromId} thisId={params.row.id}  actionButtonsConfig={modeldata.actionButtons} handleRefresh={handleRefresh} handleThisRefresh={handleThisRefresh} />
          )
      });
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid>

        <DataGrid
          rows={data}
          columns={columns}
        />
      </Grid>
      <MemoizedImagePreview
        showLargerImg={showLargerImg}
        previewTop={previewTop}
        previewLeft={previewLeft}
        previewInfo={previewInfo}
      />
    </div>
  );
};

GridTable.defaultProps = {
  data: [],
  section: '',
  modeldata: null,
  specialTable: null,
  handleClose: () => {}
};

GridTable.propTypes = {
  data: PropTypes.array,
  section: PropTypes.string,
  modeldata: PropTypes.object,
  specialTable: PropTypes.string,
  handleClose: PropTypes.func
};

export default GridTable;
