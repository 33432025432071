import React, { useState, useEffect } from "react";
import {
  DataGridPro,
  useGridApiRef,
  GridRowOrderChangeParams,
  GridToolbar
} from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { Modal, Box, Button, Grid, Tooltip } from "@mui/material";
import ModalButtonsAddTo from '../Modal/ModalButtonsAddTo';
import PdfButtons from "../PDF/PdfButtons";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import LinkIcon from '@mui/icons-material/Link';
import { useSelectOptions } from '../../hooks/useSelectOptions';
import SavePublicDataButton from "../CacheSaving/SavePublicDataButton";
import SavePrivateDataButton from "../CacheSaving/SavePrivateDataButton";
import { useImagePreview, MemoizedImagePreview } from "../../utils/imagePreview";
import ExcelButton from "../ExcelExport/ExcelButton";


export default function GridTable({
  data,
  section,
  modeldata,
  specialTable = null,
  showGenerationButton = true,
}) {
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const apiRef = useGridApiRef();
  const [rowOrder, setRowOrder] = useState([]);
  const [modalOpenSet, setModalOpenSet] = useState(false);
  const [modalOpenNarrative, setModalOpenNarrative] = useState(false);
  const [modalOpenLocation, setModalOpenLocation] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);


  const { handleMouseOver, handleMouseOut, handleMouseMove, showLargerImg, previewTop, previewLeft, previewInfo } = useImagePreview();



  // Update the row order state when the data changes
  useEffect(() => {
    setRowOrder(data.map((row) => row.id));
  }, [data]);



  let showInTableKey = "showInTable";
  if (specialTable != null) {
    showInTableKey = "showIn" + specialTable;
  }

  const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: '#666'
  };



  const selectMappings = useSelectOptions(modeldata?.fields);

  const bucketPhotos = modeldata?.bucketPhotos || "cb-business-manager";

  const columns = modeldata?.fields
    .filter((field) => field[showInTableKey])
    .map((field) => ({
      field: field.key,
      headerName: field.title,
      flex: field.key === 'id' || field.key === 'main_image' ? 0 : 1,
      width: field.key === 'id' ? 50 : field.key === 'main_image' ? 90 : null, 
     //editable: true,
      renderCell: (params) => {
        let finalValue = params.value;
          if ((field.fieldType === "select" || field.fieldType === "autocomplete" || field.fieldType === "autocompleteWithQuery") && selectMappings[field.key]) {
            if(selectMappings[field.key][finalValue]!='' && selectMappings[field.key][finalValue]!=null && !field.noMapping){
            finalValue = selectMappings[field.key][finalValue] + ' ('+ params.value+')' || finalValue;
            }
          }
          if (field.linkInTable) {
            finalValue = <Link style={linkStyle}  to={`/${section}/detail/${params.row.id}`}>{finalValue}</Link>;
          }
          if (field.linkFkInTable) {
            const sectionToLink = field.linkFkTo || field.key.replace("_id", "");
            finalValue = <div style={{display: 'inline'}}>{finalValue} <Tooltip title={`This link will point to the ${sectionToLink} section`}><Link style={linkStyle} to={`/${sectionToLink}/detail/${params.value}`}><LinkIcon/></Link></Tooltip></div>;
          }
        if (field.renderImg && params.value) {
          const thumbnail = `https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${section}/${params.row.id}/main_image/${params.value}-50-40.jpg?123`;
            return (
            <img 
              src={thumbnail} 
              loading="lazy"
              onMouseOver={(e) => handleMouseOver(e, section, params.row.id, params.value)}
              onMouseOut={handleMouseOut}
              onMouseMove={handleMouseMove}
            alt="Thumbnail"
            />
          );
        }
        return finalValue;;
      }
    }));



    const handleSelectedRowsOnClick = () => {
      const selectedRowsMap = apiRef.current.getSelectedRows();
      setSelectedRowIds(Array.from(selectedRowsMap.keys()));
    };
    
    const resetSelectedRows = () => {
      setSelectedRowIds([]);
    };
  
    useEffect(() => {
      console.log(selectedRowIds); // Logging the array of IDs
    }, [selectedRowIds]);

    const handleRefresh = () => {
      console.log('handleRefresh')
    };

    if (!modeldata) return null; // or show a loading spinner





  return (
    <div>
<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
<Box sx={{ flexGrow: 1 }}>
<Button
    style={{ marginBottom: '10px' }}
    onClick={() => setCheckboxSelection(!checkboxSelection)}
  >
    <CheckBoxOutlinedIcon/>
  </Button>
  <Button style={{ marginRight: '10px', marginBottom: '10px' }} onClick={handleSelectedRowsOnClick}>
  <VideogameAssetIcon/>
  </Button>
  </Box>
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginRight: '10px'}}>
  {modeldata?.savePublicDataButton && showGenerationButton && (
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <SavePublicDataButton section={section} data={modeldata?.savePublicDataButton} />
  </Box>)}

  {modeldata?.savePrivateDataButton && showGenerationButton && (
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <SavePrivateDataButton section={section} data={modeldata?.savePrivateDataButton} />
  </Box>)}

    {modeldata.addButtons && (
  <ModalButtonsAddTo 
          onClickHandle={handleSelectedRowsOnClick} 
          buttonNames={modeldata?.addButtons} 
          section={section} 
          selectedRowIds={selectedRowIds} 
          resetSelectedRows={resetSelectedRows}
          handleRefresh={handleRefresh}
        />)
      }


  {modeldata.pdfTableButtons && (
<PdfButtons onClickHandle={handleSelectedRowsOnClick} buttonNames={modeldata?.pdfTableButtons} section={section} selectedRowIds={selectedRowIds} resetSelectedRows={resetSelectedRows} />
)}

{modeldata.excelTableButtons && (
<ExcelButton onClickHandle={handleSelectedRowsOnClick} buttonNames={modeldata?.excelTableButtons} section={section} selectedRowIds={selectedRowIds} resetSelectedRows={resetSelectedRows} />
)}





  </Box>
</div>
<Grid item xs={12} style={{ width: '100%', maxWidth: '100vw' }}>
      <DataGridPro
        sx={{ height: 'calc(100vh - 20px)', width: "100%" }} 
          editMode="row"
          apiRef={apiRef}
          rows={data}
          columns={columns}
          pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          pagination
          checkboxSelection={checkboxSelection}
          processRowUpdate={(newRow) => newRow} // Simple row update logic
          onProcessRowUpdateError={(error) => console.error(error)}
          onRowEditCommit={(params) => console.log("Edited row:", params)}
          slots={{ toolbar: GridToolbar }}
        />
      </Grid>
      <MemoizedImagePreview
        showLargerImg={showLargerImg}
        previewTop={previewTop}
        previewLeft={previewLeft}
        previewInfo={previewInfo}
      />
     
    </div>
  );
}
