import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, TextField, Button, Grid, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { loadItems } from "../../../api"; // Adjust the import path as needed


const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};


const StepFourSale = ({ formData, handleInputChange }) => {
  const [searchInput, setSearchInput] = useState("");
  const [artpartnersOptions, setArtpartnersOptions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const queryClient = useQueryClient();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredCount, setFilteredCount] = useState(artpartnersOptions.length);
  

  

    useEffect(() => {
      const fetchAllData = async () => {
        try {
          const urls = [
              `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/artpartners.json?129934`
          ];
          const [artpartnersOptionsFetch] = await Promise.all(urls.map(fetchData));

          setArtpartnersOptions(artpartnersOptionsFetch);

        } catch (error) {
          console.error("Fetching error:", error);
        }
      };
  
      fetchAllData();
    }, []);




  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['artpartners', offset],
    queryFn: () => loadItems('artpartners', { search: searchInput, limit: 1000, offset: offset}, 'id,name'),
    initialData: artpartnersOptions,
    enabled: filteredCount < 4 && searchInput.length > 4,
    staleTime: 4 * 1000
  });


  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueDataMap = new Map();
      
      // Add existing options to the map
      artpartnersOptions.forEach(item => uniqueDataMap.set(item.id, item));
  
      // Add new data to the map, duplicates will be overridden
      data.forEach(item => uniqueDataMap.set(item.id, item));
  
      // Convert the map back to an array
      const uniqueData = Array.from(uniqueDataMap.values());
      
      setArtpartnersOptions(uniqueData);
      setFetchCount(fetchCount + 1);
  
      console.log('Unique data updated', uniqueData);
    }
  }, [data]);





  const count = () => {
    console.log("art aprt lenght", artpartnersOptions.length);
    console.log("offset", offset);
  };


  const handleRefetch = () => {
  // refetch();
};

useEffect(() => {
  const selectedOption = artpartnersOptions.find(option => option.id === formData.artpartners_id);
  if (selectedOption) {
    setSearchInput(selectedOption.name + ' ' + selectedOption.id);
  }
}, [formData.artpartners_id, artpartnersOptions]);

const filterOptions = (options, { inputValue }) => {
  const filtered = options.filter(
    option => option.name.toLowerCase().includes(inputValue.toLowerCase())
  );
  setFilteredCount(filtered.length);
  return filtered;
};

  return (
    <div>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8}>
        <Autocomplete
         value={artpartnersOptions.find(option => option.id === formData.artpartners_id) || null}
          onInputChange={(event, newInputValue) => {
            setSearchInput(newInputValue);
          }}
          onChange={(event, newValue) => {handleInputChange("artpartners_id", newValue ? newValue.id : null);
          }}
          filterOptions={filterOptions}
          options={artpartnersOptions}
          getOptionLabel={(option) => option.name+ ' ' +option.id}
          renderOption={(props, option) => (
            <li {...props} key={option.id}> {/* Use the unique 'id' as key */}
              {option.n || option.name} - {option.id}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Choose Art Partners" variant="outlined" fullWidth margin="normal" />
          )}
          noOptionsText={<div>No options available, try another way like First_Name Name or ID number</div>}
          />
      </Grid>
      <Grid item xs>
        <Button onClick={handleRefetch}><RefreshIcon/></Button>
        </Grid>
        <Grid item xs>Filtered Options Count: {filteredCount}
        </Grid>
    </Grid>
          </div>
  );
}

export default StepFourSale;
