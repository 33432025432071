import React, { useState, useEffect } from 'react';
import { Button, Select, InputLabel, FormControl, MenuItem, TextField } from '@mui/material';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { saveItemJson } from '../../api';

function MakeDynamicJson() {
  const [tableToGet, setTableToGet] = useState('');
  const [id, setId] = useState(); 
  const [name, setName] = useState(null); // Initialize with an empty string
  const [goodToGo, setGoodToGo] = useState(null); // Initialize with null if you prefer

  const queryClient = useQueryClient();


    const query = useQuery({ 
      queryKey: ['items', tableToGet, id], 
      queryFn: () => saveItemJson(tableToGet, id),
      enabled: !!goodToGo
    });


     const response = query.data || [];
     console.log(response.statusCode);

     const messageFromS3 = response.message+' '+response.statusCode;

     const handleSelectChange = (event) => {
      setTableToGet(event.target.value);
      setName(event.target.value);
    };


    const handleFieldChange = (event) => {
      setId(event.target.value);
    };



const handleGetData = () => {
  setGoodToGo(true)
};

useEffect(() => {
  // Check if the query has data and the status code is 200
  if (query.data && query.data.statusCode === 200) {
    setGoodToGo(null); // Reset goodToGo to null
  }
}, [query.data]); 


  return (
    <div>
      {messageFromS3}
      <br/>


<TextField
    sx={{ width: "100%", my: 1 }}
    key="id"
    label="id"
    value={id}
    variant="outlined"
    margin="normal"
    onChange={handleFieldChange}
  />





    <FormControl fullWidth margin="normal">
      <InputLabel>Select Database</InputLabel>
      <Select
        label="Select Database"
        value={tableToGet}
        onChange={handleSelectChange}
      >
        <MenuItem key="entities" value="entities">entities</MenuItem>
        <MenuItem key="establishments" value="establishments">establishments</MenuItem>
        <MenuItem key="contacts" value="contacts">contacts</MenuItem>
        <MenuItem key="banks" value="banks">banks</MenuItem>
        <MenuItem key="artists" value="artists">artists</MenuItem>
        <MenuItem key="employees" value="employees">employees</MenuItem>
        <MenuItem key="users" value="users">users</MenuItem>
        <MenuItem key="accounts" value="accounts">accounts</MenuItem>
        <MenuItem key="fairs" value="fairs">fairs</MenuItem>
        <MenuItem key="fairs_outposts" value="fairs_outposts">fairs_outposts</MenuItem>
        <MenuItem key="fairs_franchises" value="fairs_franchises">fairs_franchises</MenuItem>
        <MenuItem key="offsite_places" value="offsite_places">offsite_places</MenuItem>
        <MenuItem key="shippers" value="shippers">shippers</MenuItem>
        <MenuItem key="artpartners" value="artpartners">artpartners</MenuItem>
        <MenuItem key="artworks" value="artworks">artworks</MenuItem>
        <MenuItem key="medias" value="medias">medias</MenuItem>
        <MenuItem key="journalists" value="journalists">journalists</MenuItem>
        <MenuItem key="sales" value="sales">sales</MenuItem>
        <MenuItem key="acquisitions" value="acquisitions">acquisitions</MenuItem>
        <MenuItem key="exhibitions" value="exhibitions">exhibitions</MenuItem>
        <MenuItem key="sets_of_artworks" value="sets_of_artworks">sets_of_artworks</MenuItem>
        <MenuItem key="sets_of_assets" value="sets_of_assets">sets_of_assets</MenuItem>
        <MenuItem key="sets_of_contacts" value="sets_of_contacts">sets_of_contacts</MenuItem>
        <MenuItem key="incoming_consignments" value="incoming_consignments">incoming_consignments</MenuItem>
        <MenuItem key="outgoing_consignments" value="outgoing_consignments">outgoing_consignments</MenuItem>
        <MenuItem key="photographers" value="photographers">photographers</MenuItem>
        <MenuItem key="conventions" value="conventions">conventions</MenuItem>
        <MenuItem key="roles" value="roles">roles</MenuItem>
        <MenuItem key="permissions" value="permissions">permissions</MenuItem>
        <MenuItem key="contacts_categories" value="contacts_categories">contacts categories</MenuItem>
        <MenuItem key="all_artists" value="all_artists">all artists</MenuItem>
        <MenuItem key="hours_model" value="hours_model">hours model</MenuItem>
        <MenuItem key="deliveries" value="deliveries">deliveries</MenuItem>
        {/* Add more menu items here */}

      </Select>
    </FormControl>

    <Button variant="contained" color="secondary" onClick={handleGetData}>
      Generate Json File
    </Button>

  {query.data &&  <div><br/><br/>{messageFromS3}</div>}
  </div>
  );
}

export default MakeDynamicJson;