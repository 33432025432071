import { Font } from "@react-pdf/renderer";

const fonts = () => {
 
Font.register({
    family: "Museo100",
    src: "https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans/MuseoSans_100.otf",
  });
  Font.register({
    family: "Museo300",
    src: "https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans/MuseoSans_300.otf",
  });
  Font.register({
    family: "Museo500",
    src: "https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans/MuseoSans_500.otf",
  });
  
  Font.register({
    family: "MuseoSlab300",
    src: "https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSlab/MuseoSlab_300.otf",
  });
  
  Font.register({
    family: "MuseoSlab500",
    src: "https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSlab/MuseoSlab_500.otf",
  });
  
  Font.register({
    family: "Museo300i",
    src: "https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans/MuseoSans_300_Italic.otf",
  });
  
  Font.register({
    family: "NotoRegular",
    src: "https://cb-global-fonts.s3.eu-west-3.amazonaws.com/Noto/NotoSansJP-Regular.ttf",
  });
};

export default fonts;