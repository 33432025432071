import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { loadItem } from '../../api';// Ensure this path matches where your API functions are defined

function SavePublicDataButton({section, id=null, data, single=false}) {

  // Setup the query client and mutation
  const queryClient = useQueryClient();

  let dataLoadItem = {generate_public_data: true};
  let linkSuffix = "table";
  if(single) {
linkSuffix = id;
dataLoadItem = {generate_public_data: true, single_file: true};

if(data?.noSuffix){
  linkSuffix = "main_single";
}
  } 



  const mutation = useMutation({
    mutationFn: () =>
    loadItem(section, id, dataLoadItem),
    onSuccess: () => {
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });

  const handleSaveClick = () => {
    if (!section) {
      alert('Please set both section and ID before saving.');
      return;
    }
    mutation.mutate();
  };



const buttonHelp = data?.helpText || "This will generate a hard coded file that will be used by the public website";
const buttonName = data?.buttonName || "Generate Public Data";
  return (
    <div>
      <Button sx={{width: '100%'}} variant="outlined" size="medium" color="primary" onClick={handleSaveClick} disabled={mutation.isLoading}>
        {buttonName}
        <Tooltip title={buttonHelp}>
          <InfoIcon sx={{cursor: 'help'}}color="primary" />
      </Tooltip>
      </Button>

      {mutation.isLoading && <p>Saving...</p>}
      {mutation.isError && <p>Error: {mutation.error.message}</p>}
      {mutation.isSuccess && (
  <div>
    <p>
      File saved successfully!
      <a  href={`${process.env.REACT_APP_CBP_PUBLIC_DATA_URL}cbp-public-json/${section}/${section}_${linkSuffix}.json`} >
        view
      </a>
    </p>
  </div>
)}
    </div>
  );
}

export default SavePublicDataButton;
