import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Help from './Help'; // Import the HelpComponent

function HelpButton({ helpData }) {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div>
      <Button sx={{marginTop:'5px'}} onClick={() => setShowHelp(!showHelp)}> {showHelp ? "Hide Help" : "Show Help"}</Button>
      {showHelp && <Help helpData={helpData} />}
    </div>
  );
}


export default HelpButton;