import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { loadItem } from '../../api';// Ensure this path matches where your API functions are defined

function SavePrivateDataButton({section, id=null, data, single=false}) {

  // Setup the query client and mutation
  const queryClient = useQueryClient();

  let dataLoadItem = {generate_private_data: true};



  let link = `dynamic_json/${section}`;
  if(single) {
    link = `single_file_json/${section}_${id}`;
dataLoadItem = {generate_private_data: true, single_file: true};
  }

  const mutation = useMutation({
    mutationFn: () =>
    loadItem(section, id, dataLoadItem),
    onSuccess: () => {
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });

  const handleSaveClick = () => {
    if (!section) {
      alert('Please set both section and ID before saving.');
      return;
    }
    mutation.mutate();
  };



const buttonHelp = data?.helpText || "This will generate a hard coded file that will be used by this Private App";
const buttonName = data?.buttonName || "Generate Private Data";
  return (
    <div>
      <Button sx={{width: '100%', height:"auto"}} variant="outlined" color="primary" onClick={handleSaveClick} disabled={mutation.isLoading}>
        {buttonName}
        <Tooltip title={buttonHelp}>
          <InfoIcon sx={{cursor: 'help'}} color="primary"/>
      </Tooltip>
      </Button>

      {mutation.isLoading && <p>Saving...</p>}
      {mutation.isError && <p>Error: {mutation.error.message}</p>}
      {mutation.isSuccess && (
  <div>
    <p>
    File saved successfully!
      <a  href={`${process.env.REACT_APP_GLOBAL_MAPPING_URL}${link}.json`} >
        view
      </a>
    </p>
  </div>
)}
    </div>
  );
}

export default SavePrivateDataButton;
