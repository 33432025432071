import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';


Font.register({ family: 'Museo100', src: 'https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans_300.otf' });


// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Museo100',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});



// Create Document Component
const MyPDF = ({itemData}) => {

  console.log('final final iteldata',itemData);
  return (

  <Document>
    <Page size={[288, 432]} style={styles.page}>
      <View style={styles.section}>
        <Text>{itemData.name}</Text>
      </View>
      <View style={styles.section}>
        <Text>{itemData.public_price}</Text>
      </View>
    </Page>
  </Document>
);
  }

export default MyPDF;