import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';




const styles = StyleSheet.create({
    footerContainer: {
      position: 'absolute', // Position the footer absolutely
      bottom: 0, // Stick to the bottom
      left: 0,
      right: 0,
      width: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
      fontFamily: 'Museo100',
      flexDirection: 'row',
      justifyContent: 'space-between', // This will space out your items
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 30,
    },
    footerItem: {
      fontFamily: 'Museo100',
      fontSize: 9,
      width: '33%',
      textAlign: 'center', // Center text in each section
    },
    footerLeft: {
      fontFamily: 'Museo100',
      textAlign: 'left', // Align text to the left for the left section
    },
    footerRight: {
      fontFamily: 'Museo100',
      textAlign: 'right', // Align text to the right for the right section
    },
    header: {
      letterSpacing:'3px',
      fontFamily: 'Museo100',
      fontSize: 14,
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    footer: {
      fontFamily: 'Museo100',
      fontSize: 8,
      textAlign: 'center',
      margin: 10,
    }
  });

export const Header = () => <Text style={styles.header}>Ceysson & Bénétière</Text>;

export const FooterWithPageNumber = ({ pageIndex, totalPages }) => (
  <View style={styles.footerContainer}>
    <Text style={[styles.footerItem, styles.footerLeft]}>loic@ceysson.com</Text>
    <Text style={styles.footerItem}>www.ceyssonbenetiere.com</Text>
    <Text style={[styles.footerItem, styles.footerRight]}>
      {pageIndex + 1} of {totalPages}
    </Text>
  </View>
);


export const Footer = () => (
    <View style={styles.footerContainer}>
      <Text style={[styles.footerItem, styles.footerLeft]}>loic@ceysson.com</Text>
      <Text style={styles.footerItem}>www.ceyssonbenetiere.com</Text>
      <Text style={[styles.footerItem, styles.footerRight]}>
        +33 6 84 40 99 92
      </Text>
    </View>
  );