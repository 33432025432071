import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  List,
  Paper,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import dayjs from "dayjs";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { getDisplayValue } from "../../utils/utils";
import { usePermissions } from "../../contexts/PermissionsContext";

const EditView = ({ section, item_data, modelData, handleEditClick }) => {
  const { permissions } = usePermissions();
  const selectMappings = useSelectOptions(modelData.fields);


  const groupFieldsByCardOrganization = (fields) => {
    const groupedFields = {};

    fields.forEach((field) => {
      const cardOrg = field.cardOrganization || "Main Info";
      if (groupedFields[cardOrg]) {
        groupedFields[cardOrg].push(field);
      } else {
        groupedFields[cardOrg] = [field];
      }
    });

    return groupedFields;
  };

  let canModify = false;

  if (modelData?.permissions?.permissions_to_modify) {
    canModify = permissions.includes(`can_modify_${section}`);
  } else {
    canModify = true;
  }

  return (
    <>
      {Object.entries(groupFieldsByCardOrganization(modelData.fields)).map(
        ([cardOrg, fields]) => (
          <Box key={cardOrg}>
            <Typography variant="h6" component="h2" sx={{ letterSpacing: 1 }}>
              {cardOrg}
            </Typography>
            <Grid container spacing={2} key={cardOrg}>
              {fields.map((field) => {
                if (field.showInDetail) {
                  if (field.fieldType === "text") {
                    return (
                      <Paper
                        elevation={0}
                        style={{
                          border: "1px dotted lightgray", // light border
                          backgroundColor: "white", // light grey background
                          elevation: 0, // no elevation
                        }}
                        key={field.key}
                        sx={{
                          width: field.paperWidth || "100%",
                          marginLeft: 2,
                          marginTop: 4,
                          p: 5,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" component="h6">
                            {field.title.charAt(0).toUpperCase() +
                              field.title.slice(1)}
                          </Typography>
                          {field.editable &&
                            !item_data["is_file_closed"] &&
                            canModify && (
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={() => handleEditClick(field)}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                        </Box>
                        <Typography
                          variant="body2"
                          component="p"
                          dangerouslySetInnerHTML={{
                            __html: item_data[field.key],
                          }}
                        />
                      </Paper>
                    );
                  } else {
                    // Render list item for non-'text' field types
                    if (
                      field.showInDetailConditionNull &&
                      item_data[field.key] === null
                    ) {
                      return null;
                    } else {
                      return (
                        <Grid item xs={4} key={field.key}>
                          <List sx={{ listStyleType: "none" }}>
                            <ListItem key={field.key}>
                              <ListItemText
                                style={{ textTransform: "capitalize" }}
                                primary={
                                  <span>
                                    {field.title.replace(/_/g, " ")}
                                    {/* This is your primary text */}
                                    {field?.helpText && (
                                      <Tooltip title={field.helpText}>
                                        <IconButton
                                          size="small"
                                          style={{
                                            marginLeft: "5px",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <InfoIcon sx={{cursor: 'help'}} fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </span>
                                }
                                secondary={getDisplayValue(
                                  field,
                                  item_data[field.key],
                                  selectMappings,
                                  true
                                )}
                              />
                              {field.editable &&
                                !item_data["is_file_closed"] &&
                                canModify && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="edit"
                                      onClick={() =>
                                        handleEditClick(
                                          field,
                                          field?.conditionalFileLoad
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                            </ListItem>
                          </List>
                        </Grid>
                      );
                    }
                  } 
                }
                return null;
              })}
            </Grid>
          </Box>
        )
      )}
    </>
  );
};

export default EditView;
