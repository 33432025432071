import { useState, useEffect } from "react";

const useModelLoader = (section) => {
  const [modelData, setModelData] = useState(null);
  const [filteredFields, setFilteredFields] = useState(null);

  useEffect(() => {
    let modelFileExtension = "json";
    let model = section;
    if (
      /^sets_of_/.test(section) ||
      /^narratives_/.test(section) ||
      /^narrative_/.test(section) ||
      /^locations/.test(section)
    ) {
      modelFileExtension = "js";
    } else if (/^history_/.test(section)) {
      model = "history";
    } else if (/^final_invoices_entities_/.test(section)) {
      model = "final_invoices_entities";
    }

    

    // Dynamic import of the model
    import(`../model/${model}.${modelFileExtension}`)
      .then((importedModel) => {
        setModelData(importedModel.default);

        // Check if the model and fields property exist
        if (importedModel.default && importedModel.default.fields) {
          const fields = importedModel.default.fields.filter(
            (field) => field.showInAddModal
          );
          setFilteredFields(fields);
        }
      })
      .catch((error) =>
        console.error(`Error importing model for section "${model}":`, error)
      );
  }, [section]);

  return { modelData, filteredFields };
};

export default useModelLoader;
