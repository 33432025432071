// modalConfig.js
import AddLocationModal from "../Modal/AddLocationModal";
import AddToAnythingMtmModal from "./AddToAnythingMtmModal";
import AddAnythingMtmOrFkModal from "./AddAnythingMtmOrFkModal";
import AddSalesModal from "./AddSalesModal";
//import AddFinalInvoicesModal from "./AddFinalInvoicesModal";
//import AddNarrativeModal from "../Modal/AddNarrativeModal";

export const modalStyles = {
  regular: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400, 
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  },
  large: {
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    transform: "translate(-50%, -50%)",
    width: 800, 
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  },
};

const modalConfig = {
  defaultStyle: modalStyles.regular,
  sets: {
    component: AddAnythingMtmOrFkModal,
    label: "Add a Set and add to it",
  },
  sets_to: {
    finalTarget: "sets",
    component: AddToAnythingMtmModal,
    label: "Add to an existing set"
  },
  narratives: {
    component: AddAnythingMtmOrFkModal,
    prefixWithTargetName:true,
    label: "Add a Narrative",
  },
  locations_artworks: {
    component: AddLocationModal,
    label: "Add a Location for this/these artwork(s)",
  },
  locations_assets: {
    component: AddLocationModal,
    label: "Add a Location for this/these asset(s)",
  },
  locations_crates: {
    component: AddLocationModal,
    label: "Add a Location for this/these crate(s)",
  },
  exhibitions: {
    component: AddToAnythingMtmModal,
    label: "Add To Exhibition",
  },
  establishments: {
    component: AddToAnythingMtmModal,
    label: "Add To Establishment",
  },
  fairs: {
    component: AddToAnythingMtmModal,
    label: "Add To Fair",
  },
  texts: {
    component: AddAnythingMtmOrFkModal,
    label: "Add a Text",
    manyOrFk: "fk"
  },
  dimensions_artworks: {
    component: AddAnythingMtmOrFkModal,
    label: "Add dimensions to this work",
    manyOrFk: "fk"
  },
  sales: {
    component: AddSalesModal,
    label: "Add a sale",
    style: modalStyles.large,
  },
  acquisitions: {
    component: AddAnythingMtmOrFkModal,
    label: "Add an acquistion",
    style: modalStyles.large,
  },
  incoming_consignments: {
    component: AddToAnythingMtmModal,
    label: "Add to incoming consignments ",
    style: modalStyles.large,
  },
  outgoing_consignments: {
    component: AddToAnythingMtmModal,
    label: "Add to outgoing consignments ",
    style: modalStyles.large,
  },  
  deliveries: {
    component: AddAnythingMtmOrFkModal,
    label: "Add a delivery ",
    manyOrFk: "fk_self",
    style: modalStyles.large,
  },
  artpartners: {
    component: AddAnythingMtmOrFkModal,
    label: "Add a new artpartner"
  },
  artpartners_to: {
    finalTarget: "artpartners",
    component: AddToAnythingMtmModal,
    label: "Add to an existing artpartner"
  },
  photographers_to: {
    finalTarget: "photographers",
    component: AddToAnythingMtmModal,
    label: "Add to an existing photographer"
  },
  shippers_to: {
    finalTarget: "shippers",
    component: AddToAnythingMtmModal,
    label: "Add to an existing shipper"
  },
  journalists_to: {
    finalTarget: "journalists",
    component: AddToAnythingMtmModal,
    label: "Add to an existing journalist"
  },
  addresses: {
    component: AddAnythingMtmOrFkModal,
    label: "Add an address"
  },
  phones: {
    component: AddAnythingMtmOrFkModal,
    label: "Add a phone"
  },
  emails: {
    component: AddAnythingMtmOrFkModal,
    label: "Add an email"
  },
  texts_artists: {
    component: AddAnythingMtmOrFkModal,
    label: "Add a text for this artist"
  },
  artists: {
    component: AddToAnythingMtmModal,
    label: "Add an artist"
  },
  news: {
    component: AddToAnythingMtmModal,
    label: "Add to news"
  },
  artworks: {
    component: AddToAnythingMtmModal,
    label: "Add to artworks"
  },
  assets: {
    component: AddToAnythingMtmModal,
    label: "Add to assets"
  },
  selected_press: {
    component: AddToAnythingMtmModal,
    label: "Add to selected press"
  },
  incoming_payments: {
    component: AddAnythingMtmOrFkModal,
    label: "Add incoming payment",
    manyOrFk: "fk"
  },
  outgoing_payments: {
    component: AddAnythingMtmOrFkModal,
    label: "Add outgoing payment",
    manyOrFk: "fk"
  },
  permissions: {
    component: AddToAnythingMtmModal,
    label: "Add a permission"
  },
  proforma: {
    component: AddAnythingMtmOrFkModal,
    manyOrFk: "fk",
    label: "Add a proforma"
  },
  users: {
    component: AddToAnythingMtmModal,
    label: "Add a permission",
    reverse: true
  },
  contacts: {
    component: AddToAnythingMtmModal,
    label: "Add a contact"
  },
  artists_interest: {
    component: AddToAnythingMtmModal,
    label: "Add a CB artist in interest"
  },
  artists_collection: {
    component: AddToAnythingMtmModal,
    label: "Add a CB artist in collection"
  },
  all_artists_interest: {
    component: AddToAnythingMtmModal,
    label: "Add a global artist in interest"
  },
  all_artists_collection: {
    component: AddToAnythingMtmModal,
    label: "Add a global artist in collection"
  },
  hours_by_day: {
    component: AddAnythingMtmOrFkModal,
    label: "Add hours for a day",
    manyOrFk: "fk",
  },
  hours_specific_date: {
    component: AddAnythingMtmOrFkModal,
    manyOrFk: "fk",
    label: "Add hours for a specific date"
  },
  special_messages: {
    component: AddAnythingMtmOrFkModal,
    label: "Add a special message",
    reverse: true
  },
};


export default modalConfig;
