import React, { useState, useEffect, useMemo, useRef } from 'react';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import CustomGlobalEverythingPDF from './CustomGlobalEverythingPDF';
import { FormControl,Checkbox, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import axios from 'axios';
import useModelLoader from '../../hooks/useModelLoader';

function MakeGlobalPdfFormModal({ itemData, query, section, selectedRowIds }) {
  const [entriesPerPage, setEntriesPerPage] = useState('1');
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({main_image: true});
  const [checkboxCustomValues, setCheckboxCustomValues] = useState({});



  const handleRadioChange = (event) => {
    setEntriesPerPage(event.target.value);
  };

  const { modelData, isLoading: isModelDataLoading } = useModelLoader(section);

  useEffect(() => {
    if (modelData?.fields) {
      const initialCheckboxValues = modelData.fields.reduce((acc, field) => {
        if (field.pdfOptions) {
          acc[field.key] = field.pdfOptions === 'default';
        }
        return acc;
      }, {});
      setCheckboxValues(initialCheckboxValues);
    }
  }, [modelData?.fields]);


  // Handle checkbox changes
  const handleCheckboxChange = (event) => {
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked
    });
  };


  const handleCustomOptionChange = (event) => {
    setCheckboxCustomValues({
      ...checkboxCustomValues,
      [event.target.name]: event.target.checked
    });
  };

  // Function to render checkboxes for fields with 'pdfOptions'
  const renderPdfOptionsCheckboxes = () => {
    return modelData?.fields
      .filter(field => field.pdfOptions)
      .map(field => (
        <FormControlLabel
          key={field.key}
          control={
            <Checkbox
              checked={checkboxValues[field.key] || false}
              onChange={handleCheckboxChange}
              name={field.key}
            />
          }
          label={field.title}
        />
      ));
  };
    // Using useRef to persist the axios instance
    const api = useRef(
      axios.create({
        baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
        headers: {
          "Content-Type": "application/json"
        },
      })
    ).current;
  
    api.interceptors.request.use(
      config => {
        const token = localStorage.getItem('CognitoJWTToken');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  


    const timestamp = new Date().getTime(); 
    useEffect(() => {
      if (selectedRowIds != null && selectedRowIds.length > 0) {
        const fetchItems = async () => {
          try {
            setIsLoading(true);
            const idsString = selectedRowIds.join(',');
            const selectedFields = Object.keys(checkboxValues).filter(field => checkboxValues[field]);
            const queryParams = new URLSearchParams({
              table: section,
              ids: idsString,  
              fields: selectedFields.join(','), // Pass only selected fields to the query parameters
            });
            const response = await api.get(`?${queryParams.toString()}`);        
            setData(response.data);
            console.log('API Called Response:', response.data);
          } catch (err) {
            console.error('Error:', err);
            setError(err);
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchItems();
      }
    }, [section, selectedRowIds, checkboxValues]); // Make sure to include checkboxValues in the dependency array
    





  const documentMemo = useMemo(() => {
    if (!data) {
      return null;
    }

    let ComponentToCall;

    ComponentToCall = CustomGlobalEverythingPDF;
    return (
      <ComponentToCall
      checkedFields={checkboxValues}
      checkedCustomFields={checkboxCustomValues} 
        entriesPerPage={parseInt(entriesPerPage)}
        section={section}
        data={data}
      />
    );
  }, [entriesPerPage, section, data, checkboxValues, checkboxCustomValues]);

  useEffect(() => {
    // Reset the blob URL to force regeneration
    setPdfBlobUrl(null);
  }, [documentMemo, checkboxValues]);


  if (isLoading || isModelDataLoading) {
    return <div>Loading...</div>; // Handle loading state for both API data and model data
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* Form Section */}
      <div style={{ width: '50%' }}>
        <form>
          {/* Options for entries per page */}
          <FormControl component="fieldset">
            <FormLabel component="legend">Entries Per Page</FormLabel>
            <RadioGroup
              aria-label="entries-per-page"
              name="entriesPerPage"
              value={entriesPerPage}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="1 Entry per Page" />
              <FormControlLabel value="4" control={<Radio />} label="4 Entries per Page" />
              <FormControlLabel value="10" control={<Radio />} label="10 Entries per Page" />
            </RadioGroup>
            {modelData?.pdfConfig?.hasBarcode && (
        <div>
          <FormControlLabel
          key="barcode"
          control={
            <Checkbox
              checked={checkboxCustomValues["barcode"] || false}
              onChange={handleCustomOptionChange}
              name="barcode"
            />
          }
          label="Barcode"
        />
      
        </div>
      )}
                        <FormControlLabel
          key="cblogo"
          control={
            <Checkbox
              checked={checkboxCustomValues["cblogo"] || false}
              onChange={handleCustomOptionChange}
              name="cblogo"
            />
          }
          label="CB Logo on Labels"
        />
            {modelData?.fields && (
        <div>
          {renderPdfOptionsCheckboxes()}
        </div>
      )}
          </FormControl>
          <br />
  
          {/* PDF Download Link */}
          <PDFDownloadLink 
            document={documentMemo}
            fileName="myfile.pdf"
            style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
          >
            {({ blob, url, loading, error }) => loading ? 'Loading document...' : 'Download now!'}
          </PDFDownloadLink>

  
          {/* BlobProvider for live preview */}
          <BlobProvider checkedFields={checkboxValues} checkedCustomFields={checkboxCustomValues} document={documentMemo} key={entriesPerPage}>
            {({ blob, url, loading, error }) => {
              if (!loading && blob && !error && url !== pdfBlobUrl) {
                setPdfBlobUrl(url);
              }
              return null;
            }}
          </BlobProvider>
        </form>
      </div>
  
      {/* PDF Preview Section */}
      <div style={{ width: '50%' }}>
        {pdfBlobUrl && (
          <iframe 
            src={pdfBlobUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="PDF Preview"
          />
        )}
      </div>
      
    </div>
  );
  
}

export default MakeGlobalPdfFormModal;
