import React from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function Login({ setUser, isLoading, authOkay }) {
    function handleLogin() {
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
    }


    return (

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        

          <Button variant="contained" onClick={handleLogin}>Open Google</Button>

      </Box>
    );
}

export default Login;
