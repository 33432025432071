
import React, { useState } from 'react';
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExcelModalTrigger from './ExcelModalTrigger';
import excelModalConfig from './excelModalConfig';
const ExcelButton = ({ buttonNames, onClickHandle, section, selectedRowIds, itemData, fromTable, fromId, ...additionalProps}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    if (typeof onClickHandle === 'function') {
      onClickHandle();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <Button 
      sx={{
        width: '100%',
        color: 'white',
        backgroundColor: 'primary.main',
      }}
      aria-controls="add-to-menu" 
      aria-haspopup="true" 
      endIcon={<ArrowDropDownIcon />}
      onClick={handleClick}>
        Excel
      </Button>
      <Menu id="add-to-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {buttonNames.map((buttonName, index) => {
          const ModalComponent = excelModalConfig[buttonName]?.component;
          const label = excelModalConfig[buttonName]?.label;
          return (
            <MenuItem key={index} onClick={handleClose}>
              {ModalComponent && <ExcelModalTrigger type={buttonName} label={label} section={section} itemData={itemData} selectedRowIds={selectedRowIds} excelModalConfig={excelModalConfig} fromTable={fromTable} fromId={fromId} {...additionalProps}/>}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ExcelButton;

