import { useState, useEffect } from "react";
import * as Constants from "../constants/selectConstants"; // Adjust the path as needed

export const useSelectOptions = (fields) => {
    const [selectMappings, setSelectMappings] = useState({});
  
    useEffect(() => {
      if (!fields || fields.length === 0) {
        // Handle the case where fields are not yet available
        return;
      }
  
    fields.forEach((field) => {
      if (field.fieldType === "select" || field.fieldType === "autocomplete" || field.fieldType === "autocompleteWithQuery") {
        if (field.selectLocalValue && Constants[field.selectLocalValue]) {
          // Local constants
          setSelectMappings((prev) => ({
            ...prev,
            [field.key]: Constants[field.selectLocalValue].reduce(
              (acc, curr) => {
                acc[curr.id] = curr.n ? curr.n : curr.name;
                return acc;
              },
              {}
            ),
          }));
        } else if (field.selectFile) {
          let filePath;
          if(field.fieldType === "autocompleteWithQuery"){
            filePath = "dynamic_json/"+field.selectFile+".json?12333";         
           }
          else{
           filePath = field.selectFile;
          }

          const s3Url = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${filePath}?`;
          fetch(s3Url)
            .then((response) => {
              if (!response.ok) throw new Error("Network response was not ok.");
              return response.json();
            })
            .then((data) => {
              setSelectMappings((prev) => ({
                ...prev,
                [field.key]: data.reduce((acc, curr) => {
                  acc[curr.id] = curr.n ? curr.n : curr.name;
                  return acc;
                }, {}),
              }));
            })
            .catch((error) =>
              console.error("Failed to load select options from S3", error)
            );
        }
      }
    });
  }, [fields]);

  return selectMappings;
};
