import React, { useState, useEffect, useMemo, useRef } from "react";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import InvoiceProformaPDF from "./InvoiceProformaPDF";
import {
  FormControl,
  Checkbox,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Slider,
} from "@mui/material";
import axios from "axios";
import useModelLoader from "../../hooks/useModelLoader";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { getDisplayValue } from "../../utils/utils";

function MakeProformaModal({
  itemData,
  query,
  section,
  selectedRowIds,
  ...additionalProps
}) {
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customValues, setCustomValues] = useState({
    size : "A4",
    language : "fr",
    showPriceBreakDown: false,
    showCents: true,
    priceFormatting: 'fr-FR'
  });



  const [fileName, setFileName] = useState("invoice.pdf");



  const handleFileName = (filenameback) => {
    setFileName(filenameback);
};



  const { modelData, isLoading: isModelDataLoading } = useModelLoader(section);



  const handleOptionChange = (event) => {
    setCustomValues({
      ...customValues,
      [event.target.name]: event.target.value,
    });
  };


  const handleCheckboxChange = (event) => {
    setCustomValues({
      ...customValues,
      [event.target.name]: event.target.checked
    });
  };

  console.log('additionalProps', additionalProps)
  console.log('itemData', itemData)

  // Using useRef to persist the axios instance
  const api = useRef(
    axios.create({
      baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).current;

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("CognitoJWTToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


  useEffect(() => {
    if (selectedRowIds != null && selectedRowIds.length > 0) {
      const fetchItems = async () => {
        try {
          setIsLoading(true);
          let queryParams = {
            table: "sales",
            from_id: itemData.sales_id,
            custom_specific: "get_all_info_for_invoice"
          };

          const queryString = new URLSearchParams(queryParams).toString();
          const response = await api.get(`?${queryString}`);
          setData(response.data);


  
        } catch (err) {
          console.error("Error:", err);
          setError(err);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchItems();
    }
  }, [itemData, selectedRowIds]); 
  

  
  const LetterInvoice = section === "proforma" ? "PA" :  "A";


  const documentMemo = useMemo(() => {
    if (!data) {
      return null;
    }

    let ComponentToCall;

    ComponentToCall = InvoiceProformaPDF;
    return (
      <ComponentToCall
        customValues={customValues}
        section={section}
        invoiceId = {selectedRowIds}
        data={data[0]}
        handleFileName={handleFileName}
      />
    );
  }, [
    section,
    data,
    customValues
  ]);


  

  useEffect(() => {
    // Reset the blob URL to force regeneration
    setPdfBlobUrl(null);
  }, [documentMemo, customValues]);

  if (isLoading || isModelDataLoading) {
    return <div>Loading...</div>; // Handle loading state for both API data and model data
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {/* Form Section */}
      <div
        style={{
          width: "50%",
          paddingRight: "30px",
          height: "100%",
          overflow: "auto",
        }}
      >
        <form>
          <FormControl component="fieldset">
            <div>
              <FormLabel component="legend">Label Size</FormLabel>
              <RadioGroup
                aria-label="size"
                name="size"
                value={customValues["size"]}
                onChange={handleOptionChange}
                row
              >
                <FormControlLabel value="A4" control={<Radio />} label="A4" />
                <FormControlLabel value="LETTER"  control={<Radio />} label="LETTER" />

              </RadioGroup>


              <FormLabel component="legend">Language</FormLabel>
              <RadioGroup
                aria-label="language"
                name="language"
                value={customValues["language"]}
                onChange={handleOptionChange}
                row
              >
                <FormControlLabel value="fr" control={<Radio />} label="FR" />
                <FormControlLabel value="en"  control={<Radio />} label="EN" />
                <FormControlLabel value="ja" control={<Radio />} label="JA" />

              </RadioGroup>
              <FormLabel component="legend">Price Formatting</FormLabel>
              <RadioGroup
                aria-label="priceFormatting"
                name="priceFormatting"
                value={customValues["priceFormatting"]}
                onChange={handleOptionChange}
                row
              >
                <FormControlLabel value="fr-FR" control={<Radio />} label="FR" />
                <FormControlLabel value="en-US"  control={<Radio />} label="US" />
                <FormControlLabel value="ja-JP"  control={<Radio />} label="JP" />
                <FormControlLabel value="de-DE" control={<Radio />} label="DE" />

              </RadioGroup>


              <FormControlLabel
          key="showCents"
          control={
            <Checkbox
              checked={customValues["showCents"] || false}
              onChange={handleCheckboxChange}
              name="showCents"
            />
          }
          label="show Cents"
        />

              <FormLabel component="legend">Price Details</FormLabel>
              <FormControlLabel
          key="showPriceBreakDown"
          control={
            <Checkbox
              checked={customValues["showPriceBreakDown"] || false}
              onChange={handleCheckboxChange}
              name="showPriceBreakDown"
            />
          }
          label="show Price Break Down"
        />
                      <FormControlLabel
          key="showVAT"
          control={
            <Checkbox
              checked={customValues["showVAT"] || false}
              onChange={handleCheckboxChange}
              name="showVAT"
            />
          }
          label="show VAT (only if flat rate)"
        />


            </div>
          </FormControl>
          <br />

          {/* PDF Download Link */}
          <PDFDownloadLink
            document={documentMemo}
            fileName={fileName}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>

          {/* BlobProvider for live preview */}
          <BlobProvider
            invoiceId = {selectedRowIds}
            section="artpartners"
            customValues={customValues}
            document={documentMemo}
            key={customValues}
            handleFileName={handleFileName}
          >
            {({ blob, url, loading, error }) => {
              if (error) {
                console.error("Error in BlobProvider: ", error);
              }
              if (!loading && blob && !error && url !== pdfBlobUrl) {
                setPdfBlobUrl(url);
              }
              return null;
            }}
          </BlobProvider>
        </form>
      </div>

      {/* PDF Preview Section */}
      <div style={{ width: "50%", paddingLeft: "20px" }}>
        {pdfBlobUrl && (
          <iframe
            src={pdfBlobUrl}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="PDF Preview"
          />
        )}
      </div>
    </div>
  );
}

export default MakeProformaModal;
