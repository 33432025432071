import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  CircularProgress,
  Typography,
} from "@mui/material";

const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};




const StepThreeSale = ({ formData, handleInputChange }) => {
  const [entitiesOptions, setEntitiesOptions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const urls = [
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/accounts.json?11233`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}payment_type.json?133312233`,
        ];
        const [entitiesOptions, paymentOptions] = await Promise.all(urls.map(fetchData));
        setEntitiesOptions(entitiesOptions);
        setPaymentOptions(paymentOptions);
      } catch (error) {
        console.error("Fetching error:", error);
      }
    };

    fetchAllData();
  }, []);

  return (
    <div>
<FormControl fullWidth margin="normal" sx={{ my: 1 }}>
  <InputLabel id="status-label">Choose Accounts</InputLabel>
  <Select
    labelId="status-label"
    label="Choose Accounts"
    value={formData.accounts_id || ""}
    sx={{ width: "100%", my: 1 }}
    title="Choose Accounts"
    onChange={(e) => handleInputChange("accounts_id", e.target.value)}
  >
    {entitiesOptions?.filter(option => option.entities_id === formData.entities_id && option.is_active===1) // Replace someNumber with the actual number you want to filter by.
      .map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
  </Select>
</FormControl>


<FormControl fullWidth margin="normal" sx={{ my: 1 }}>
  <InputLabel id="status-label">Choose Payment Method</InputLabel>
  <Select
    labelId="status-label"
    label="Choose payment method"
    value={formData.payment_method || ""}
    sx={{ width: "100%", my: 1 }}
    title="Choose payment method"
    onChange={(e) => handleInputChange("payment_method", e.target.value)}
  >
    {paymentOptions?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.n}
        </MenuItem>
      ))}
  </Select>
</FormControl>
      <br />

    </div>
  );
};

export default StepThreeSale;
