import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {
  return (
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
    sx={{ display: 'flex' }}>
      <CircularProgress sx={{color: '#2B766C'}}/>
    </Box>
  );
}

