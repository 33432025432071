import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
  Autocomplete,
  Select,
  MenuItem,
  FormControlLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";



const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};


const StepFiveSale = ({ formData, handleInputChange, formDataEmployee, handleInputChangeEmployee }) => {
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [establishmentsOptions, setEstablishmentsOptions] = useState([]);
  const [salesLocOptions, setSalesLocOptions] = useState([]);
  const [fairsOptions, setFairsOptions] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const urls = [
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/employees.json?19991233`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/establishments.json?11233`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}sales_location_type.json?11233`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/fairs.json?11233`,

        ];
        const [employeesOptions, establishmentsOptions, salesLocOptions, fairsOptions] = await Promise.all(urls.map(fetchData));
        setEmployeesOptions(employeesOptions);
        setEstablishmentsOptions(establishmentsOptions);
        setSalesLocOptions(salesLocOptions);
        setFairsOptions(fairsOptions);
      } catch (error) {
        console.error("Fetching error:", error);
      }
    };

    fetchAllData();
  }, []);


  const handleInputDateChange = (key, value) => {
    if (value && value !='0000-00-00') {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          console.log("Formatted date:", formattedDate);
    
          handleInputChange(key, formattedDate)
          console.log('formattedDate', formattedDate)
          console.log(formData)
        }
      };


return (
    <div>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <Autocomplete
  multiple
  onChange={(event, newValue) => {
    // Map over the newValue array to extract IDs
    handleInputChangeEmployee("employees_id", newValue.map(item => item.id));
  }}
  value={employeesOptions.filter(option => 
    formDataEmployee.employees_id && formDataEmployee.employees_id.includes(option.id))} // Ensure formData.employees_id is checked for undefined
  options={employeesOptions}
  getOptionLabel={(option) => option.name + ' ' + option.id}
  renderInput={(params) => (
    <TextField {...params} label="Choose Sales Person among Employees" variant="outlined" fullWidth margin="normal" />
  )}
  noOptionsText={<div>No options available, try another way like First_Name Name or ID number</div>}
/>
      </Grid>
      <Grid item xs={12}>
      <FormControl fullWidth margin="normal" sx={{ my: 1 }}>
        <InputLabel id="status-label">Choose sales_location_type</InputLabel>
        <Select
          labelId="status-label"
          label="Choose Entity"
          value={formData.sales_location_type || ""}
          sx={{ width: "100%", my: 1 }}
          title="Choose Entity"
          onChange={(e) => handleInputChange("sales_location_type", e.target.value)}
        >
          {salesLocOptions?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.n}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      {formData.sales_location_type === 2 ? (
      <Grid item xs={12}>
        <Autocomplete
          key="fairs_id"
          onChange={(event, newValue) => handleInputChange("fairs_id", newValue ? newValue.id : null)}
          value={fairsOptions.find(option => option.id === formData.fairs_id) || null}
          options={fairsOptions}
          getOptionLabel={(option) => option.name+ ' ' +option.id}
          renderInput={(params) => (
            <TextField {...params} label="Choose  art fair where sales took place" variant="outlined" fullWidth margin="normal" />
          )}
          />
      </Grid>
      ) :  formData.sales_location_type === 1 ? (


      <Grid item xs={12}>
        <Autocomplete
          key="establishments_id"
          onChange={(event, newValue) => handleInputChange("establishments_id", newValue ? newValue.id : null)}
          value={establishmentsOptions.find(option => option.id === formData.establishments_id) || null}
          options={establishmentsOptions}
          getOptionLabel={(option) => option.name+ ' ' +option.id}
          renderInput={(params) => (
            <TextField {...params} label="Choose Establishments where sales took place" variant="outlined" fullWidth margin="normal" />
          )}
          />
      </Grid>
      ):null}

<Grid item xs={12}>
<DatePicker
              sx={{ width: "100%", my: 1 }}
                label="Deal Start Date"
                format="DD/MM/YYYY"
                value={dayjs(formData.deal_start_date) || dayjs()}
                onChange={(newDate) => handleInputDateChange("deal_start_date", dayjs(newDate))}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
              />

    </Grid>
    <Grid item xs={12}>
<DatePicker
              sx={{ width: "100%", my: 1 }}
                label="deal end date"
                format="DD/MM/YYYY"
                value={dayjs(formData.deal_end_date) || dayjs()}
                onChange={(newDate) => handleInputDateChange("deal_end_date", dayjs(newDate))}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
              />

    </Grid>
    </Grid>
          </div>
  );
}

export default StepFiveSale;
