import React, { useState, useEffect } from 'react';
import useAuth from './hooks/useAuth';
import { PermissionsProvider } from './contexts/PermissionsContext'; 
import UserMainContent from './UserMainContent';
import Login from "./components/Login/Login";
import Loading from "./components/Loading/Loading";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';



function App() {
  const { user, authLoading, setUser, authOkay, handleLogout, permissions } = useAuth();


  const [readyToShowLogin, setReadyToShowLogin] = useState(false);

  useEffect(() => {
    if (!authLoading) {
      const timer = setTimeout(() => setReadyToShowLogin(true), 500);
      return () => clearTimeout(timer);
    }
  }, [authLoading]);


  return (
    <PermissionsProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          {authLoading || !readyToShowLogin ? (
            <Loading />
          ) : authOkay ? (
            <UserMainContent permissions={permissions}/>
          ) : (
            <Login setUser={setUser} />
          )}
        </div>
      </LocalizationProvider>
    </PermissionsProvider>
  );
}

export default App;
