// src/utils/getTranslations.js

const getTranslations = async (fileNames) => {
    const translations = {};
  
    const baseUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}translations/`;
    console.log(baseUrl);
  
    const fetchPromises = fileNames.map(fileName =>
      fetch(`${baseUrl}${fileName}.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          Object.keys(data).forEach(key => {
            if (!translations[key]) {
              translations[key] = data[key];
            } else {
              translations[key] = { ...translations[key], ...data[key] };
            }
          });
        })
        .catch(error => console.error('There was a problem with your fetch operation:', error))
    );
  
    await Promise.all(fetchPromises);
  
    return translations;
  };
  
  export default getTranslations;