import React, { useState, useCallback } from 'react'; // Add useEffect


export const useImagePreview = () => {
  const [showLargerImg, setShowLargerImg] = useState(false);
  const [previewInfo, setPreviewInfo] = useState({ section: '', rowId: '', value: '', fileObject: '', bucketPhotos: ''});
  const [previewTop, setPreviewTop] = useState(0);
  const [previewLeft, setPreviewLeft] = useState(0);

  const handleMouseOver = useCallback((e, section, rowId, value, fileObject="", bucketPhotos="") => {
    setShowLargerImg(true);
    setPreviewInfo({ section, rowId, value, fileObject, bucketPhotos});
    setPreviewTop(e.pageY - 450);
    setPreviewLeft(e.pageX + 30);
  }, []);

  const handleMouseOut = useCallback(() => {
    setShowLargerImg(false);
  }, []);

  const handleMouseMove = useCallback((e) => {
    const distanceFromTop = e.pageY - window.scrollY;
    if(distanceFromTop < 200) {
      setPreviewTop(e.pageY +30);
    } else if(distanceFromTop < 450) {
        setPreviewTop(e.pageY - 200);
      }
      else{
      setPreviewTop(e.pageY - 450);
    }
    setPreviewLeft(e.pageX + 30);
  }, []);

  return {
    handleMouseOver,
    handleMouseOut,
    handleMouseMove,
    showLargerImg,
    previewTop,
    previewLeft,
    previewInfo,
  };
};


const ImagePreview = ({ showLargerImg, previewTop, previewLeft, previewInfo }) => {
  if (!showLargerImg) {
    return null;
  }

  const bucketPhotos = previewInfo.bucketPhotos || "cb-business-manager";
  let imgUrl = `${previewInfo.section}/${previewInfo.rowId}/main_image/${previewInfo.value}-900-600.jpg`;
  if(previewInfo.fileObject !== ""){
    imgUrl = previewInfo.fileObject.replace("-450-300.jpg", "-900-600.jpg");
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: previewTop,
        left: previewLeft,
        zIndex: 9999,
      }}
    >
      <img
        src={`https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${imgUrl}`}
        alt="Larger preview"
        style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
      />
    </div>
  );
};

export const MemoizedImagePreview = React.memo(ImagePreview);
