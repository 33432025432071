import React, { useState } from 'react';
import { Popover, Button } from '@mui/material';
import FilePreview from './FilePreview';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


const HoverablePreviewTrigger = ({ section, id, content }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    // Anchor the popover to the current target (button)
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        style={{ color: '#9e9e9e', backgroundColor: 'white' }} 
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      >
        <VisibilityOutlinedIcon />
      </Button>
      <Popover
        onMouseLeave={handlePopoverClose}
        id="mouse-over-popover"
        sx={{
          '& .MuiPopover-paper': {
            maxHeight: '80vh',
            overflowY: 'auto',
            maxWidth: '100%',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom', // The vertical alignment of the anchor (top of the button)
          horizontal: 'left', // The horizontal alignment of the anchor (right side of the button)
        }}
        transformOrigin={{
          vertical: 'top', // The vertical origin of the transformation (bottom of the popover)
          horizontal: 'left', // The horizontal origin of the transformation (right side of the popover)
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{ pointerEvents: 'auto' }}
        >
          <FilePreview section={section} id={id} />
        </div>
      </Popover>
    </div>
  );
};

export default HoverablePreviewTrigger;
