import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button, FormControl, Checkbox, Grid, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import axios from 'axios';
import useModelLoader from '../../hooks/useModelLoader';
import ExcelJS from 'exceljs';

function MakeGlobalExcelFormModal({ itemData, query, section, selectedRowIds }) {
  const [entriesPerPage, setEntriesPerPage] = useState('1');
  const [excelBlobUrl, setExcelBlobUrl] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({});

  const handleRadioChange = (event) => {
    setEntriesPerPage(event.target.value);
  };

  const { modelData, isLoading: isModelDataLoading } = useModelLoader(section);

  useEffect(() => {
    if (modelData?.fields) {
      const initialCheckboxValues = modelData.fields.reduce((acc, field) => {
        if (field.pdfOptions) {
          acc[field.key] = field.pdfOptions === 'default';
        }
        return acc;
      }, {});
      setCheckboxValues(initialCheckboxValues);
    }
  }, [modelData?.fields]);

  // Handle checkbox changes
  const handleCheckboxChange = (event) => {
    console.log()
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked
    });
    console.log("checkboxValues",checkboxValues);
  };

  // Function to render checkboxes for fields with 'pdfOptions'
  const renderPdfOptionsCheckboxes = () => {
    return modelData?.fields
      .filter(field => field.pdfOptions)
      .map(field => (
        <Grid item xs={3}>
        <FormControlLabel

          key={field.key}
          control={
            <Checkbox
              checked={checkboxValues[field.key] || false}
              onChange={handleCheckboxChange}
              name={field.key}
            />
          }
          label={field.title}
        /></Grid>
      ));
  };


   // Using useRef to persist the axios instance
   const api = useRef(
    axios.create({
      baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
      headers: {
        "Content-Type": "application/json"
      },
    })
  ).current;

  api.interceptors.request.use(
    config => {
      const token = localStorage.getItem('CognitoJWTToken');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );



  const handleGenerateExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Table');
  
    // Get an array of selected headers
    const selectedHeaders = Object.keys(checkboxValues).filter(key => checkboxValues[key]);
  
    // Add headers row
    worksheet.addRow(selectedHeaders);
  
    // Add data rows
    if (data) {
      data.forEach(item => {
        const rowData = selectedHeaders.map(header => item[header]);
        worksheet.addRow(rowData);
      });
    }
  
    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      setExcelBlobUrl(url);
    });
  };
  


  useEffect(() => {
    if (selectedRowIds != null && selectedRowIds.length > 0) {
      const fetchItems = async () => {
        try {
          setIsLoading(true);
          const idsString = selectedRowIds.join(',');
          const selectedFields = Object.keys(checkboxValues).filter(field => checkboxValues[field]);
          const queryParams = new URLSearchParams({
            table: section,
            ids: idsString,  
            fields: selectedFields.join(','), // Pass only selected fields to the query parameters
          });
          const response = await api.get(`?${queryParams.toString()}`);        
          setData(response.data);
          console.log('API Called Response:', response.data);
        } catch (err) {
          console.error('Error:', err);
          setError(err);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchItems();
    }
  }, [section, selectedRowIds, checkboxValues]); 

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* Form Section */}
      <div style={{ width: '100%' }}>
        <form>
          {/* Options for entries per page */}
          <FormControl component="fieldset">

            {modelData?.fields && (
                  <Grid container spacing={2}>
          {renderPdfOptionsCheckboxes()}
          </Grid>
      )}
   
          </FormControl>

          {/* Generate Excel button */}
          <Button variant="contained" color="primary" onClick={handleGenerateExcel}>Generate Excel</Button>

          {/* BlobProvider for live preview */}
          {excelBlobUrl && (
            <a href={excelBlobUrl} download="invoice.xlsx">Download Excel</a>
          )}
        </form>
      </div>
      
      {/* Excel Preview Section */}
      <div style={{ width: '50%' }}>
        {/* You can preview the Excel file here if needed */}
      </div>
    </div>
  );
}

export default MakeGlobalExcelFormModal;
