import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsConfig from "../aws-exports";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { usePermissions } from '../contexts/PermissionsContext'; // import the usePermissions hook


// Configuration Setup
const isLocalhost = Boolean(window.location.hostname === "localhost" || window.location.hostname === "[::1]" || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
const [localRedirectSignIn, productionRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(",");
const [localRedirectSignOut, productionRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(",");
Amplify.configure({
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
  },
});



function useAuth() {
  const [user, setUser] = useState(null);
  const [fetchedPermissions,setFetchedPermissions] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("CognitoJWTToken") || null);
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const [authLoading, setAuthLoading] = useState(true);
  const [authOkay, setAuthOkay] = useState(false);

  const navigate = useNavigate();



  const api = axios.create({
    baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });


  const storeToken = (jwtToken) => {
    let decodedToken = JSON.parse(atob(jwtToken.split(".")[1]));
    localStorage.setItem("CognitoJWTToken", jwtToken);
    setToken(jwtToken);
    localStorage.setItem("TokenExpiry", decodedToken.exp.toString());
  };

  const isTokenExpired = () => {
    let expiryTime = localStorage.getItem("TokenExpiry");
    if (!expiryTime) {
      return true;
    }
    return Math.floor(Date.now() / 1000) > parseInt(expiryTime);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem("StoredUserDataFromDb41");
      localStorage.removeItem("CognitoJWTToken");
      localStorage.removeItem("TokenExpiry");
      localStorage.removeItem("userId");
      await Auth.signOut();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  // Authentication Check
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
        console.log(userData);
        storeToken(userData.signInUserSession.idToken.jwtToken);
        setAuthOkay(true);
      } catch (error) {
        setUser(null);
        setAuthOkay(false);
      } finally {
        setAuthLoading(false);
      }
    };

    const listener = Hub.listen("auth", ({ payload: { event } }) => {
      if (event === "signIn" || event === "cognitoHostedUI") {
        checkAuth();
      } else if (event === "signOut") {
        handleLogout();
      }
    });

    checkAuth();
    return () => Hub.remove("auth", listener);
  }, []);


    // Fetch User Data
    useEffect(() => {
      if (token && user && !userId) {
        console.log('user email?', user.attributes.email);
        api.get(`?table=users&loopforfields=loop&email=${user.attributes.email}`)
          .then(data => {
            if (data?.data[0]?.id !== userId) {
            setUserId(data?.data[0]?.id);
            console.log('data', data);
            console.log('user id?', data?.data[0]?.id);
            localStorage.setItem("userId", JSON.stringify(data?.data[0]?.id));
            localStorage.setItem("StoredUserDataFromDb", JSON.stringify(data.data[0]));
            setAuthOkay(true);
          }
          })
          .catch(error => {
            console.error("Error fetching user data: ", error);
            if (error.response && error.response.status === 401) {
              // Token might be expired or invalid, handle accordingly
            }
          });
      }
    }, [user, token, api, userId]);


    


    useEffect(() => {
      if (userId && !fetchedPermissions) {
        console.log('Fetching additional data for userId:', userId);
        api.get(`?table=users&custom_specific=get_user_permission&from_id=${userId}`)
          .then(response => {
            console.log('Additional data:', response.data);
            setFetchedPermissions(response.data.map(p => p.name));
          })
          .catch(error => {
            console.error("Error fetching additional data: ", error);
          });
      }
    }, [userId, api, fetchedPermissions]);



  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired()) {
        handleLogout();
      }
    }, 60000); // Check every minute
    return () => clearInterval(interval);
  }, []);

  return { user, authLoading, setUser, token, authOkay, handleLogout, permissions: fetchedPermissions};
}

export default useAuth;
