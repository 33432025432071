
import MakeArtworksLabelsModal from "./MakeArtworksLabelsFormModal";
import MakeGlobalPdfFormModal from "./MakeGlobalPdfFormModal";
import MakeSpecificArtworksPdfFormModal from "./MakeSpecificArtworksPdfFormModal";
import MakeProformaModal from "./MakeProformaModal";


export const modalStyles = {
  regular: {
    position: "absolute",
    top: "5vw",
    left: "5vw",
    display: "flex",
    width: "87vw", 
    height: "80vh", 
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 10,
    overflow: 'auto',
    p: 4
  },
  large: {
    position: "absolute",
    top: "20%",
    left: "20%",
    display: "flex",
    transform: "translate(-20%, -20%)",
    width: 800, 
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: 'auto',
    p: 4
  },
};

const pdfModalConfig = {
  defaultStyle: modalStyles.regular,
  global_table: {
    component: MakeGlobalPdfFormModal,
    query: true,
    label: "Make a General PDF for these records",
  },
  global_details: {
    component: MakeGlobalPdfFormModal,
    query: false,
    label: "Make a General PDF for this records",
  },
  specific_artworks_table: {
    component: MakeSpecificArtworksPdfFormModal,
    query: true,
    label: "Make a specific PDF for these records",
  },
  artworks_labels: {
    component: MakeArtworksLabelsModal,
    query: true,
    label: "Make Logistics Labels for these records",
  },
  proforma: {
    component: MakeProformaModal,
    query: true,
    label: "Make a proforma invoice",
  },
  finalInvoice: {
    component: MakeProformaModal,
    query: true,
    label: "Make a final invoice",
  },
};


export default pdfModalConfig;
