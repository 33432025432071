import React, { useState, useEffect } from "react";
import { DataGridPro, useGridApiRef, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';

import { Modal, Box, Button, Grid, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ModalButtonsAddTo from '../Modal/ModalButtonsAddTo';
import PdfButtons from "../PDF/PdfButtons";
import { useImagePreview, MemoizedImagePreview } from "../../utils/imagePreview";
import { useSelectOptions } from '../../hooks/useSelectOptions';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { updateManyItems } from '../../api';
import ActionButtons from "../ActionButtons/ActionButtons";


export default function GridTable({ data, section, modeldata, handleClose, fromTable={fromTable}, fromId={fromId}, specialTable=null, handleRefresh, handleThisRefresh }) {
    const apiRef = useGridApiRef();
    const [rowOrder, setRowOrder] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [checkboxSelection, setCheckboxSelection] = useState(false);

    const { handleMouseOver, handleMouseOut, handleMouseMove, showLargerImg, previewTop, previewLeft, previewInfo } = useImagePreview();


 const keyFields = [`${fromTable}_id`, `${section}_id`];
 const junctionTable = `${fromTable}_${section}`;


 const mutation = useMutation({
    mutationFn: updatedRows => updateManyItems(junctionTable, keyFields, updatedRows, true),
    onSuccess: (data) => {
        // Handle success - this is where you might want to invalidate queries or update local state
        console.log("Rows updated successfully", data);
    },
    onError: (error) => {
        // Handle error
        console.error("Error updating rows", error);
    },

});




    
const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    const updatedOrder = [...rowOrder];
    const movedRow = updatedOrder.splice(params.oldIndex, 1)[0];
    updatedOrder.splice(params.targetIndex, 0, movedRow);

    setRowOrder(updatedOrder); // Update the state with the new order
    // Create an array of objects with id and position
    const rowsWithPositions = updatedOrder.map((id, index) => ({
        [`${fromTable}_id`]: fromId,
        [`${section}_id`]: id,
        sort_position: index
    }));
    mutation.mutate(rowsWithPositions, {
        onSuccess: () => {
            // Handle successful mutation
            console.log("Rows updated successfully");
        },
        onError: (error) => {
            // Handle error case
            console.error("Error updating rows", error);
        }
    });
    console.log(rowsWithPositions);
};

        // Update the row order state when the data changes
        useEffect(() => {
            setRowOrder(data.map(row => row.id));
        }, [data]);

        const selectMappings = useSelectOptions(modeldata?.fields);



        const handleSelectedRowsOnClick = () => {
            const selectedRowsMap = apiRef.current.getSelectedRows();
            setSelectedRowIds(Array.from(selectedRowsMap.keys()));
          };
          
          const resetSelectedRows = () => {
            setSelectedRowIds([]);
          };
        
          useEffect(() => {
            console.log(selectedRowIds); // Logging the array of IDs
          }, [selectedRowIds]);

    if (!modeldata) return null; // or show a loading spinner




    let showInTableKey = 'showInTable';
    if(specialTable!=null){
         showInTableKey = 'showIn' + specialTable;
    }


    const columns = modeldata.fields
    .filter(field => field[showInTableKey])
    .map(field => {
      const flexValue = field.key === 'id' ? 0.3 : 1;
  
      return {
        field: field.key,
        headerName: field.title,
        flex: flexValue,
        minWidth: 50,
        renderCell: (params) => {
          let finalValue = params.value;
          if (field.fieldType === "select" && selectMappings[field.key]) {
            finalValue = selectMappings[field.key][finalValue] || finalValue;
          }
          if (field.linkInTable) {
            finalValue = <Link onClick={handleClose} to={`/${section}/detail/${params.row.id}`}>{finalValue}</Link>;
          }
          if (field.linkFkInTable) {
            const sectionToLink = field.linkFkTo || field.key.replace("_id", "");
            finalValue = <div style={{display: 'inline'}}>{finalValue} <Tooltip title={`This link will point to the ${sectionToLink} section`}><Link onClick={handleClose} to={`/${sectionToLink}/detail/${params.value}`}><LinkIcon/></Link></Tooltip></div>;
          }
          if (field.renderImg && params.value) {
            const thumbnail = `${process.env.REACT_APP_S3_BASE_IMG}${section}/${params.row.id}/main_image/${params.value}-50-40.jpg`;
                 return (
              <img 
                src={thumbnail} 
                loading="lazy"
                onMouseOver={(e) => handleMouseOver(e, section, params.row.id, params.value)}
                onMouseOut={handleMouseOut}
                onMouseMove={handleMouseMove}
              alt="Thumbnail"
              />
            );
          }
          return finalValue;
        }
      };
    });

        if (modeldata && modeldata.actionButtons && Object.keys(modeldata.actionButtons).includes(fromTable)) {
            columns.push({
                field: "actions",
                headerName: "Actions",
                flex: 0.3,
                renderCell: (params) => (
                    <ActionButtons section={section} fromTable={fromTable} fromId={fromId} thisId={params.row.id}  actionButtonsConfig={modeldata.actionButtons} handleRefresh={handleRefresh} handleThisRefresh={handleThisRefresh} />
                )
            });
        }

    
    return (
        <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
<Box sx={{ flexGrow: 1 }}>
<Button
    style={{ marginBottom: '10px' }}
    onClick={() => setCheckboxSelection(!checkboxSelection)}
  >
    <CheckBoxOutlinedIcon/>
  </Button>
  <Button style={{ marginRight: '10px', marginBottom: '10px' }} onClick={handleSelectedRowsOnClick}>
  </Button>
  </Box>
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginRight: '10px'}}>
    {modeldata.addButtons && (
  <ModalButtonsAddTo 
          onClickHandle={handleSelectedRowsOnClick} 
          buttonNames={modeldata?.addButtons} 
          section={section} 
          selectedRowIds={selectedRowIds} 
          resetSelectedRows={resetSelectedRows}
        />)
      }
  {modeldata.pdfTableButtons && (
<PdfButtons onClickHandle={handleSelectedRowsOnClick} buttonNames={modeldata?.pdfTableButtons} section={section} selectedRowIds={selectedRowIds} resetSelectedRows={resetSelectedRows} fromTable={fromTable} fromId={fromId} />
)}
  </Box>
</div>
        <div>
            <Grid item xs={12}>
                <DataGridPro  
                    apiRef={apiRef}
                    rows={data}
                    columns={columns} 
                    rowReordering
                    checkboxSelection={checkboxSelection}
                    onRowOrderChange={handleRowOrderChange}
                />
            </Grid>
            <MemoizedImagePreview
        showLargerImg={showLargerImg}
        previewTop={previewTop}
        previewLeft={previewLeft}
        previewInfo={previewInfo}
      />
        </div>
        </div>
    );
}
