import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

import { CURRENCY_OPTIONS } from "../../../constants/selectConstants";


const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};





const StepOneSale = ({ formData, handleInputChange }) => {
  const [entitiesOptions, setEntitiesOptions] = useState([]);


  const handleInputDateChange = (key, value) => {
    if (value && value !='0000-00-00') {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          console.log("Formatted date:", formattedDate);
    
          handleInputChange(key, formattedDate)
          console.log('formattedDate', formattedDate)
          console.log(formData)
        }
      };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const urls = [
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/entities.json`,
        ];
        const [entitiesOptions] = await Promise.all(urls.map(fetchData));
        setEntitiesOptions(entitiesOptions);
      } catch (error) {
        console.error("Fetching error:", error);
      }
    };

    fetchAllData();
  }, []);

  return (
    <div>
      <TextField
        sx={{ width: "100%", my: 1 }}
        key="name"
        label="name"
        value={formData.name}
        variant="outlined"
        margin="normal"
        onChange={(e) => handleInputChange("name", e.target.value)}
      />

      <DatePicker
              sx={{ width: "100%", my: 1 }}
                label="Invoice Date"
                value={dayjs(formData.invoice_date) || dayjs}
                format="DD/MM/YYYY"
                onChange={(newDate) => handleInputDateChange("invoice_date", newDate)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
              />

      <FormControl fullWidth margin="normal" sx={{ my: 1 }}>
        <InputLabel id="status-label">Choose Entity</InputLabel>
        <Select
          labelId="status-label"
          label="Choose Entity"
          value={formData.entities_id || ""}
          sx={{ width: "100%", my: 1 }}
          title="Choose Entity"
          onChange={(e) => handleInputChange("entities_id", e.target.value)}
        >
          {entitiesOptions?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />

      <FormControl fullWidth margin="normal" sx={{ my: 1 }}>
        <InputLabel id="status-label">Choose Currency</InputLabel>
        <Select
          labelId="status-label"
          label="Choose Currency"
          value={formData.currency || ""}
          sx={{ width: "100%", my: 1 }}
          title="Choose Currency"
          onChange={(e) => handleInputChange("currency", e.target.value)}
        >
          {CURRENCY_OPTIONS?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.n}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.is_export}
            onChange={(e) => handleInputChange("is_export", e.target.checked)}
          />
        }
        label="Is an exportation"
      />


              <br />
            </div>

  );
};

export default StepOneSale;
