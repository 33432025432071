import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { loadItems } from '../../api';
import SimpleTable from '../Table/SimpleTable';
import useModelLoader from '../../hooks/useModelLoader';

function FieldHistory({ section, field, id, handleClose }) {
  const [showHistory, setShowHistory] = useState(false); // Initially false to hide history
  const { modelData } = useModelLoader('history');
  const queryClient = useQueryClient();

  const queryParams = { 'field_name': field.key, [section+'_id']: id, 'loopforfields': 'loop' };
  const history_section = 'history_' + section;

  console.log(queryParams);
  const query = useQuery({
    queryKey: ['items', history_section, queryParams],
    queryFn: () => loadItems(history_section, queryParams),
    enabled: showHistory // Only run the query if showHistory is true
  });

  // Toggle showHistory state between true and false
  const handleToggleHistory = () => {
    setShowHistory(prevShowHistory => !prevShowHistory);
  };

  // Determine the button text based on the showHistory state
  const buttonText = showHistory ? 'Hide History' : 'Show History';

  return (
    <div>
      <Button variant="text" color="primary" onClick={handleToggleHistory}>
        {buttonText}
      </Button>
      {showHistory && (
        <div>
          {query.data?.length > 0 && (
            <SimpleTable data={query.data} section={history_section} handleClose={handleClose} modeldata={modelData} specialTable="TableFieldIndividual"/>
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(FieldHistory);
