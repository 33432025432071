import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { loadItem } from '../../api';// Ensure this path matches where your API functions are defined

function ErasePublicDataButton({section, id=null, data, single=false}) {

  // Setup the query client and mutation
  const queryClient = useQueryClient();

  let dataLoadItem = {generate_public_data: true, erase: true};
  let linkSuffix = "table";
  if(single) {
linkSuffix = id;
dataLoadItem = {generate_public_data: true, single_file: true, erase: true};

if(data?.noSuffix){
  linkSuffix = "main_single";
}
  } 

  const mutation = useMutation({
    mutationFn: () =>
    loadItem(section, id, dataLoadItem),
    onSuccess: () => {
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });

  const handleSaveClick = () => {
    if (!section) {
      alert('Please set both section and ID before saving.');
      return;
    }
    mutation.mutate();
  };



const buttonHelp = data?.helpText || "This will erase the hard coded file that is used by the public website. Be careful i'ts only if you think that the file should not be acessiblke to the public in any case. It will lead to an error page";
const buttonName = data?.buttonName || "Delete Public Data";
  return (
    <div>
      <Button sx={{width: '100%', height:"auto"}} variant="outlined" color="primary" onClick={handleSaveClick} disabled={mutation.isLoading}>
        {buttonName}
        <Tooltip title={buttonHelp}>
          <InfoIcon sx={{cursor: 'help'}} color="primary" />
      </Tooltip>
      </Button>

      {mutation.isLoading && <p>Saving...</p>}
      {mutation.isError && <p>Error: {mutation.error.message}</p>}
      {mutation.isSuccess && (
  <div>
    <p>
      File deleted successfully!
      <a  href={`${process.env.REACT_APP_CBP_PUBLIC_DATA_URL}cbp-public-json/${section}/${section}_${linkSuffix}.json`} >
        Verify
      </a>
    </p>
  </div>
)}
    </div>
  );
}

export default ErasePublicDataButton;
