import React from "react";
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import useModelLoader from '../../hooks/useModelLoader';
import JsBarcode from 'jsbarcode';
import dayjs from "dayjs";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { getDisplayValue } from "../../utils/utils";
import { formattedTodaysDate } from "../../utils/utils";
import { Header, FooterWithPageNumber } from "./PdfUtils";
import fonts from "../../utils/fonts";


const styles = StyleSheet.create({
  page: {
    fontFamily: 'Museo100',
    fontSize: 7,
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 10,
    height: '100%', // Ensure the page takes full height
    justifyContent: 'flex-start'
  },
  gridContainer: {
    marginTop: 10,
    paddingLeft: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  barcodeContainer: (entriesPerPage) => ({
    height: entriesPerPage === 10 ? '20%' : '10%',
    width: '100%', // Barcode container takes full width of the section
    alignItems: 'center', // Center barcode horizontally
    marginBottom: 5, // Add some margin at the bottom of the barcode
}),
  textContainer: (entriesPerPage) => ({
    padding: 2,
    fontSize: 7,
    width: entriesPerPage === 10 ? '60%' : '100%',
    height: entriesPerPage === 10 ? '100%' : '50%',
    position: 'relative', // Ensure the container is relative
    justifyContent: 'center',
    alignItems: 'left',
    display: 'flex', // Use flex to center the image
}),
  imageContainer: (entriesPerPage) => ({
    width: entriesPerPage === 10 ? '40%' : '100%',
    height: entriesPerPage === 10 ? '100%' : entriesPerPage === 10 ? '65%' : '50%',
    position: 'relative', // Ensure the container is relative
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
   // border: '1px',
   // borderColor: 'black'
}),
  section: (entriesPerPage) => ({
    padding: 2,
    width: entriesPerPage === 1 ? '100%' : '275px', 
    height: entriesPerPage === 1 ? '720px' : entriesPerPage === 4 ? '345px' : '147px',
    flexGrow: 0, 
    flexShrink: 1, 
    marginBottom: 'auto',
    position: 'relative', 
    display: 'flex',
    flexDirection: entriesPerPage === 10 ? 'column' : 'column', 
   // border: '1px',
   // borderColor: 'blue',
    alignItems: 'flex-start',
  }),
  image: (entriesPerPage) => ({
    maxHeight: entriesPerPage === 1 ? '800px' : entriesPerPage === 4 ? '200px' : '70px',
    maxWidth: entriesPerPage === 1 ? '600px' : entriesPerPage === 4 ? '300px' : '120px',
    alignSelf: 'center',
  }),
  contentContainer: (entriesPerPage) => ({
    flexDirection: entriesPerPage === 10 ? 'row' : 'column', 
    width: '100%', // Content container takes full width
  }),
  footerContainer: {
    position: 'absolute', // Position the footer absolutely
    bottom: 0, // Stick to the bottom
    left: 0,
    right: 0,
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontFamily: 'Museo100',
    flexDirection: 'row',
    justifyContent: 'space-between', // This will space out your items
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 30,
  },
  footerItem: {
    fontFamily: 'Museo100',
    fontSize: 9,
    width: '33%',
    textAlign: 'center', // Center text in each section
  },
  footerLeft: {
    fontFamily: 'Museo100',
    textAlign: 'left', // Align text to the left for the left section
  },
  footerRight: {
    fontFamily: 'Museo100',
    textAlign: 'right', // Align text to the right for the right section
  },
  header: {
    letterSpacing:'3px',
    fontFamily: 'Museo100',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  footer: {
    fontFamily: 'Museo100',
    fontSize: 8,
    textAlign: 'center',
    margin: 10,
  },
  cbLogoSection: {
    width: '100%',
    height:'15px',
    fontFamily: 'Museo100',
    letterSpacing:'3px',
    fontSize: 9,
    marginTop: 5,
    textAlign: 'center',
  },
  generated: {
    width: '100%',
    height:'10px',
    fontFamily: 'Museo100',
    fontSize: 6,
    textAlign: 'center',
  },
  barcodeValue: (entriesPerPage) => ({
    fontSize: entriesPerPage === 1 ? 14 : 6, 
    width: '100%',
    fontFamily: 'Museo300',
    textAlign: 'center',
  })
});




const CustomGlobalEverythingPDF = ({ section, checkedFields, checkedCustomFields, selectedRowIds, entriesPerPage, data, ...props }) => {

  const { modelData } = useModelLoader(section);

  const selectMappings = useSelectOptions(modelData?.fields);


  const generateBarcode = (id, entriesPerPage ) => {
    let canvas = document.createElement('canvas');
    JsBarcode(canvas, id.toString().padStart(6, '0'), { 
        format: "CODE128",
        width: 4,
        height: 40, 
        fontSize: 8, 
        displayValue: false
    });
    return canvas.toDataURL("image/png");
  };



  const renderCheckedFields = (item) => {
    return modelData?.fields
      .filter(field => checkedFields[field.key]) // Filter out only checked fields
      .map(field => {
        const displayValue = getDisplayValue(field, item[field.key], selectMappings);
        return <Text style={styles.text}>{field.title}: {displayValue}</Text>;
  
      });
  };


  const chunkData = (data, size) => {
    const result = [];
    for (let i = 0; i < data.length; i += size) {
      result.push(data.slice(i, i + size));
    }
    return result;
  };

  const getTotalPages = (data, entriesPerPage) => {
    return Math.ceil(data.length / entriesPerPage);
  };

  const totalPages = getTotalPages(data, entriesPerPage);


  const groupedData = chunkData(data, entriesPerPage);



  const formattedDate = formattedTodaysDate();


  return (
    <Document>
      {groupedData.map((group, pageIndex) => (
        <Page key={pageIndex} style={styles.page}>
          <Header />
          <View style={styles.gridContainer}>
            {group.map((item, itemIndex) => (
              <View key={itemIndex} style={styles.section(entriesPerPage)}>
                {/* Barcode at the top */}

                {checkedCustomFields["barcode"] && (
<>
                  <View style={styles.barcodeContainer(entriesPerPage)}>
             
                    <Image
                      src={generateBarcode(modelData?.pdfConfig.barcodePrefix+'-'+item.id, entriesPerPage)} 
                    /><Text style={styles.barcodeValue(entriesPerPage)}>{modelData?.pdfConfig.barcodePrefix}-{item.id.toString().padStart(6, '0')}</Text>
                  </View>
                  <View style={styles.generated}>
                    <Text>Generated on {formattedDate}</Text>
                    </View>
                     </>
                )}
                {/* Content container for image and text */}
                <View style={styles.contentContainer(entriesPerPage)}>

                  {/* Image container on the left */}
                  <View style={styles.imageContainer(entriesPerPage)}>
                    <Image
                      style={styles.image(entriesPerPage)}
                      src={`${process.env.REACT_APP_S3_BASE_IMG}${section}/${item.id}/main_image/${item.main_image}-2000-1200.jpg`}
                    />
                  </View>
                  {/* Text container on the right */}
                  <View style={styles.textContainer(entriesPerPage)}>
                    {renderCheckedFields(item)}
                  </View>
                </View>
                {checkedCustomFields["cblogo"] && entriesPerPage>1 && (
                  <View style={styles.cbLogoSection}>
                  <Text>Ceysson & Bénétière</Text>
                  </View>
                )}
              </View>
            ))}
          </View>
          <FooterWithPageNumber pageIndex={pageIndex} totalPages={totalPages} />
        </Page>
      ))}
    </Document>

    
    
  );
};

export default CustomGlobalEverythingPDF;
