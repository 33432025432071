import React, { useState } from 'react';
import { Popover, Typography } from '@mui/material';

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (event) => {
    console.log("Popover opened.");
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {

    setAnchorEl(null);
  };

  return { anchorEl, openPopover, closePopover };
};

const imagePopover = ({ open, anchorEl, onClose, imageUrl, anchorV='top', anchorH='right', transformV= 'top', transformH= 'left'}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
        helloooo
      <Typography component="img" src={imageUrl} alt="Popover Image" />
    </Popover>
  );
};

export { usePopover, imagePopover };
