
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';


const countryCodes = {
    'FR': 'France',
    'US': 'United States',
    'BE': 'Belgium',
    'LU': 'Luxembourg',
    'JP': 'Japan',
    'CH': 'Switzerland'
  };

Font.register({ family: 'Museo100', src: 'https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans/MuseoSans_100.otf' });
Font.register({ family: 'Museo300', src: 'https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans/MuseoSans_300.otf' });
Font.register({ family: 'Museo500', src: 'https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSans/MuseoSans_500.otf' });
Font.register({ family: 'MuseoSlab500', src: 'https://cb-global-fonts.s3.eu-west-3.amazonaws.com/MuseoSlab/MuseoSlab_500.otf' });



// Styles for the PDF components
const getStyles = (textColor) => StyleSheet.create({
    page: {
        margin: 20,
        color: textColor
    },
    countryTitle: {
        fontFamily: 'MuseoSlab500',
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        color: textColor,
      },
      cb: {
        width: '100%',
        textAlign:'center',
        fontFamily: 'Museo100',
        fontSize: 24,
        letterSpacing: 3,
        color: textColor,
        marginBottom: 10,
        marginBottom: 20,
      },
      gridContainer: {
        marginTop: 10,
        paddingLeft: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        color: textColor
      },
      establishmentContainer: {
        marginBottom: 15,
        color: textColor
      },
      address: {
        fontFamily: 'Museo100',
        fontSize: 12,
        color: textColor
      },
      phoneNumber: {
        fontFamily: 'Museo300',
        fontSize: 12,
        color: 'grey',
        marginTop:3,
        color: textColor
      },
      city: {
        fontFamily: 'Museo500',
        marginBottom: 3,
        fontSize: 14,
        color: textColor
      },
      item: {
        padding: 2,
        width: '50%', 
        marginBottom: 'auto',
        position: 'relative', 
        color: textColor
      },
});

const countryOrder = ['FR', 'LU', 'JP', 'US', 'BE', 'CH'];



//const MakeEstablishmentsListPDF = ({ textColor="black", s3Url="https://cb-apps-global-data-mapping.s3.eu-west-3.amazonaws.com/dynamic_json/active_establishement_contact_data_for_public.json" }) => {
  const MakeEstablishmentsListPDF = ({ textColor="black", s3Url=`${process.env.REACT_APP_CBP_PUBLIC_DATA_URL}cbp-public-json/establishments/establishments_table.json` }) => {

    const [groupedEstablishments, setGroupedEstablishments] = useState({});
  const styles = getStyles(textColor);

  useEffect(() => {
    fetch(s3Url)
      .then(response => response.json())
      .then(data => {
        groupAndSetEstablishments(data);
      })
      .catch(error => {
        console.error('Error fetching establishments:', error);
      });
  }, [s3Url]);

  const groupAndSetEstablishments = (data) => {
    const openEstablishments = data.filter(est => est.open_to_public === 1);
    const grouped = openEstablishments.reduce((acc, est) => {
      const { country } = est;
      acc[country] = acc[country] || [];
      acc[country].push(est);
      return acc;
    }, {});
  
    // Sort each group of establishments by display_sort_order
    for (const country in grouped) {
      grouped[country].sort((a, b) => a.display_sort_order - b.display_sort_order);
    }
  
    // Sort the countries based on the predefined order
    const sortedGroupedEstablishments = {};
    countryOrder.forEach(countryCode => {
      if (grouped[countryCode]) {
        sortedGroupedEstablishments[countryCode] = grouped[countryCode];
      }
    });
  
    setGroupedEstablishments(sortedGroupedEstablishments);
  };
  

  const renderEstablishments = () => {
    return Object.keys(groupedEstablishments).map(country => (
      <View key={country} style={styles.establishmentContainer}>
<Text style={styles.countryTitle}>{(countryCodes[country] || country).toUpperCase()}</Text>
 <View style={styles.gridContainer}>
        {groupedEstablishments[country].map(establishment => (
          <View key={establishment.id} style={styles.item}>
                        <Text style={styles.city}>
            {establishment.display_name}
            </Text>
            <Text style={styles.address}>
              {establishment.address}
            </Text>
            <Text style={styles.address}>
                {establishment.zip_code} {establishment.city}
            </Text>
            <Text style={styles.phoneNumber}>{establishment.phone}</Text>
          </View>
          
        ))}</View>
      </View>

    ));
  };

  return <View style={styles.page}>
              <Text style={styles.cb}>
              Ceysson & Bénétière
            </Text>
    {renderEstablishments()}
    </View>;
};

export default MakeEstablishmentsListPDF;
