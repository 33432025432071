import React, { useState,useMemo, memo, } from 'react';
import { Button, Modal, Box } from "@mui/material";
import excelModalConfig, { modalStyles }  from './excelModalConfig';

const ExcelModalTrigger = ({ type, label, section, itemData, selectedRowIds, fromTable, fromId, ...additionalProps}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ModalComponent = excelModalConfig[type]?.component;
  const modalStyle = excelModalConfig[type]?.style || excelModalConfig.defaultStyle;
  const isLarge = excelModalConfig[type]?.style === modalStyles.large;
  const query = excelModalConfig[type]?.query || false;

  const idsToPost = selectedRowIds;

  console.log('additionalPropsTrigger EXCEL', additionalProps)
  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button sx={{ mb: 1 }} onClick={handleOpen}>
        {label}
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {ModalComponent && <ModalComponent section={section} selectedRowIds={idsToPost} itemData={itemData} query={query} isLarge={isLarge} fromTable={fromTable} fromId={fromId} {...additionalProps}/>}
        </Box>
      </Modal>
    </>
  );
};

export default ExcelModalTrigger;
