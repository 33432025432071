export const BOOLEAN_SELECT_OPTIONS = [{ id:0, n:'No'}, {id:1, n:'Yes'}];


export const UNIT_OPTIONS = [{ id:'cm', n:'cm'}, {id:'in', n:'in'}];

export const TZ_OPTIONS = [
    { id:'Europe/Paris', n:'Europe/Paris'}, 
    {id:'America/New_York', n:'America/New_York'}, 
    {id:'Asia/Tokyo"', n:'Asia/Tokyo"'}];


export const CURRENCY_OPTIONS = [{ id:'EUR', n:'EUR'}, {id:'USD', n:'USD'}, {id:'CHF', n:'CHF'}, {id:'JPY', n:'JPY'}];

//export const STATUS = [{ id:0, n:'DELETE'},  {id:1, n:'REGULAR'},  {id:2, n:'OK'},  {id:3, n:'TOP'}];

export const STATUS_OPTIONS = [{ id:0, n:'NO VERIFICATION'},  {id:1, n:'To Modify'},  {id:2, n:'OK'},  {id:3, n:'TOP'}];


export const LANG_OPTIONS = [{ id:'EN', n:'EN'},  {id:'FR', n:'FR'}, {id:'JA', n:'JA'}];


export const PHOTO_QUALITY = [{ id:0, n:'VERY BAD'},  {id:1, n:'BAD'},  {id:2, n:'OK'},  {id:3, n:'PHOTOGRAPHER'}];


export const DAYS_OF_WEEK_OPTIONS = [{ id:1, n:'Monday'},  {id:2, n:'Tuesday'},  {id:3, n:'Wednesday'},  
{id:4, n:'Thursday'},  {id:5, n:'Friday'},  {id:6, n:'Saturday'},  {id:7, n:'Sunday'}];



export const ALERT_STYLE_OPTIONS = [{ id:1, n:'Teal'},  {id:2, n:'Blue'},  {id:3, n:'Orange'}, {id:4, n:'Red'}];
