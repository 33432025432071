import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import fonts from "../../utils/fonts";
import JsBarcode from "jsbarcode";
import { truncateString } from "../../utils/utils";

const styles = StyleSheet.create({
  translatableText: (language) => ({
    fontFamily: language === "JA" ? "NotoRegular" : "Museo100",
  }),
  artistName: {
    paddingTop: 2,
    fontFamily: "MuseoSlab500",
    letterSpacing: 1,
    fontSize: 9,
    textTransform: "uppercase",
  },
  artworkTitle: {
    paddingTop: 3,
    fontFamily: "Museo300i",
    fontSize: 7,
  },
  artworkDate: {
    paddingTop: 3,
    fontFamily: "Museo300",
    fontSize: 7,
  },
  page: (width) => ({
    fontFamily: "Museo100",
    fontSize: 6,
    paddingLeft: width > 300 ? 20 : width < 270 ? 0 : 10,
    paddingRight: width > 300 ? 20 : width < 270 ? 0 : 10,
    paddingTop: width > 300 ? 20 : width < 270 ? 0 : 10,
    paddingBottom: width === 289 ? 10 : 0,
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%", // Ensure the page takes full height
    width: "100%",
    justifyContent: "flex-start",
  }),
  gridContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  barcodeContainer: (isLarge) => ({
    marginTop: 2,
    paddingTop: 2,
    height: isLarge === true ? "20%" : "auto",
    width: "100%",
    alignItems: "center",
    borderTop: isLarge ? "" : "1px dotted grey",
    borderBottom: isLarge ? "1px solid black" : "",
  }),
  textContainer: (isLarge) => ({
    width: isLarge === true ? "50%" : "65%",
    maxHeight: isLarge === true ? "60%" : "80%",
    minHeight: isLarge === true ? "40%" : "",
    fontSize: 7,
    paddingLeft: 5,
    borderLeft: "1px solid black",
  }),
  imageContainer: (isLarge) => ({
    width: isLarge === true ? "50%" : "30%",
    height: isLarge === true ? "200px" : "85px",
    //border: "1px solid blue",
    display: "flex",
    alignItems: "center", // This will center the image vertically
    justifyContent: "center", // This will center the image horizontally
  }),

  section: (isLarge, entriesPerPage, pageHeight, pageWidth) => {
    const heightToRemove = pageWidth < (200 * 7.2) / 2.54 ? 20 : 40;
    const availableHeight =
      pageWidth > (90 * 7.2) / 2.54 ? pageHeight - heightToRemove : 107;

    return {
      width: isLarge ? `${pageWidth - 30}px` : "255px",
      height: isLarge ? `${availableHeight}px` : "107px",
      marginBottom: entriesPerPage > 1 ? "10px" : "0px",
      position: "relative",
      display: "flex",
      //flexDirection: isLarge === true ? "column" : "row",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      border: entriesPerPage > 1 ? "1px dotted grey" : ""
    };
  },
  image: (isLarge) => ({
    padding: 2,
    maxHeight: isLarge === true ? "300px" : "70px",
    maxWidth: isLarge === true ? "450px" : "120px",
    alignSelf: "left",
    alignItems: "center",
    objectFit: "contain",
  }),
  header: (config) => ({
    letterSpacing: "3px",
    width: "100%",
    fontFamily: "Museo100",
    fontSize: config === "1_38_90" ? 7 : config === "1_102_152" ? 8 : 11,
    textAlign: "center",
    marginTop: 1,
    marginBottom: 3,
  }),
  generated: {
    width: "100%",
    height: "10px",
    fontFamily: "Museo100",
    fontSize: 6,
    textAlign: "center",
  },
  isLargeField: {
    width: "100%",
    borderBottom: "1px solid black",
    fontFamily: "Museo500",
    fontSize: 9,
    marginTop: 1,
  },
  isLargeFieldLabel: {
    width: "100%",
    fontSize: 5,
    fontFamily: "Museo100",
    marginTop: 1,
  },
  barcodeValue: (isLarge) => ({
    fontSize: isLarge === true ? 14 : 6,
    width: "100%",
    fontFamily: "Museo300",
    textAlign: "center",
  }),
});
/*
"1_38_90" 
        "1_152_102"  
       1_297_210" 
     12_297_210" */

const generateBarcode = (id) => {
  let canvas = document.createElement("canvas");
  JsBarcode(canvas, id.toString(), {
    format: "CODE128",
    width: 4,
    height: 60,
    fontSize: 9,
    displayValue: false,
  });
  return canvas.toDataURL("image/png");
};

const ArtworksLabelsPDF = ({
  section,
  customValues,
  selectedRowIds,
  data,
  ...props
}) => {
  const [entriesPerPage, setEntriesPerPage] = useState(1);
 const [pageWidth, setPageWidth] = useState(Math.floor((9 * 72) / 2.54));
 const [pageHeight, setPageHeight] = useState(Math.floor((3.8 * 72) / 2.54));
  const [isLarge, setIsLarge] = useState(false);
  const [shouldRender, setShouldRender]= useState(true);
  const [groupedData, setGroupedData] = useState([]);



  
  useEffect(() => {
    fonts();
  }, []);


  const chunkData = (data, size=12) => {
    const result = [];
    for (let i = 0; i < data.length; i += size) {
      result.push(data.slice(i, i + size));
    }
    console.log('Chunked data:', result);
    return result;
  };



  useEffect(() => {
    const updateStatesAsync = () => {
      return new Promise((resolve) => {
        setShouldRender(false);
  
        let parts = customValues.config.split("_");
        setEntriesPerPage(parts[0]);
        setPageHeight(Math.floor((parts[1] * 7.2) / 2.54));
        setPageWidth(Math.floor((parts[2] * 7.2) / 2.54));
        setIsLarge(customValues.config === "1_152_102" || customValues.config === "1_287_200");
  

        setTimeout(() => {
          resolve();
        }, 0);
      });
    };
  
    updateStatesAsync().then(() => {
      setShouldRender(true);
    });
  }, [customValues]);


  useEffect(() => {
    // Chunk data whenever entriesPerPage or data changes
    const chunkedData = chunkData(data, parseInt(entriesPerPage, 10));
    setGroupedData(chunkedData);
  }, [data, entriesPerPage]);


  return (
    <Document>
      {groupedData.map((group, pageIndex) => (
        <Page
        wrap
          key={pageIndex}
          style={styles.page(pageWidth)}
          size={[pageWidth, pageHeight]}
        >
           {shouldRender === true ? (
          <View style={styles.gridContainer}>
            {group.map((item, itemIndex) => (
              <View
                key={itemIndex}
                style={styles.section(
                  isLarge,
                  entriesPerPage,
                  pageHeight,
                  pageWidth
                )}
              >
                <Text style={styles.header(customValues.config)}>
                  Ceysson & Bénétière
                </Text>

                <View style={styles.imageContainer(isLarge)}>
                  <Image
                    style={styles.image(isLarge)}
                    src={`${process.env.REACT_APP_S3_BASE_IMG}${section}/${item.id}/main_image/${item.main_image}-450-300.jpg`}
                  />
                </View>
                <View style={styles.textContainer(isLarge)}>
                {isLarge && (
                  <View style={styles.barcodeContainer(isLarge)}>
                    <Image src={generateBarcode("CA-" + item.id)} />
                    <Text style={styles.barcodeValue(isLarge)}>
                      CA-{item.id}
                    </Text>
                  </View>)}

                {isLarge && (<Text style={styles.isLargeFieldLabel}>Artist</Text>)}
                  <Text style={styles.artistName}>{item.artists_id}</Text>

                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <Text style={styles.artworkTitle}>
                      {truncateString(item.name, 30)},{" "}
                    </Text>
                    <Text style={styles.artworkDate}>
                      {item.artworks_creation_date_start}
                    </Text>
                    {item.artworks_creation_date_end != null && (
                      <Text style={styles.artworkDate}>
                        {" "}
                        - {item.artworks_creation_date_end}
                      </Text>
                    )}
                  </View>

                  <Text>{truncateString(item.medium_en, 30)} </Text>

                  {isLarge ? (
                    <View style={{paddingTop: 10}}>
                    <Text style={styles.isLargeFieldLabel}>Dimensions cm</Text>
                      <Text style={styles.isLargeField}>
                        {item["height_cm"]} x {item["width_cm"]}
                        {item["depth_cm"] > 0 && `x ` + item["depth_cm"]} cm
                      </Text>
                      <Text style={styles.isLargeFieldLabel}>Dimensions in</Text>
                      <Text style={styles.isLargeField}>
                        {item["height_in"]} x {item["width_in"]}
                        {item["depth_in"] > 0 && `x ` + item["depth_in"]} in
                      </Text>
                      </View>
                  ) : (
                    <>
                      <Text style={{ flexDirection: "row", flexWrap: "wrap" }}>
                        {item["height_cm"]} x {item["width_cm"]}
                        {item["depth_cm"] > 0 && `x ` + item["depth_cm"]} cm /
                        {item["height_in"]} x {item["width_in"]}
                        {item["depth_in"] > 0 && `x ` + item["depth_in"]} in
                      </Text>
                    </>
                  )}

                  {isLarge && (
                    <>
                      <Text style={styles.isLargeFieldLabel}>Weight</Text>
                      <Text style={styles.isLargeField}>
                        {item.weight_kg} kg / {item.weight_lbs} lbs
                      </Text>
                      <Text style={styles.isLargeFieldLabel}>Surface</Text>
                      <Text style={styles.isLargeField}>
                        {item.area_sqm} m2
                      </Text>
                      <Text style={styles.isLargeFieldLabel}>Artwork availability</Text>
                      <Text style={styles.isLargeField}>
                         {item.artworks_availability}
                      </Text>

                    </>
                  )}
                  {!isLarge && (
                  <View style={styles.barcodeContainer(isLarge)}>
                    <Image src={generateBarcode("CA-" + item.id)} />
                    <Text style={styles.barcodeValue(isLarge)}>
                      CA-{item.id}
                    </Text>
                  </View>)}


                </View>

                {isLarge === true && (
               <View style={{ display: "flex", flexDirection: "column", height: pageWidth > 500 ? '48%' : '40%' }}> 
               {/* First View - Should take all available space */}
               <View
                   style={{
                       flexGrow: 1, // This will make the view expand
                       marginTop: 5,
                       padding: 10,
                       width: "100%",
                       border: "1px dotted grey",
                   }}
               >
                   {item.is_multiple != "No" && <Text>Framed Artwork</Text>}
                   {item.is_multiple != "No" && (
                       <Text>Edition {item.multiple_edition_number} / {item.total_edition_number}</Text>
                   )}
               </View>
           
               {/* Second View - Barcode */}
               <View
                   style={{
                       flexGrow: 0, // This will prevent the view from expanding
                       width: "100%",
                       minHeight: "10%",
                   }}
               >
                   <Image src={generateBarcode("CA-" + item.id)} />
               </View>
           
               {/* Third View - Footer Text */}
               <View
                   style={{
                       flexGrow: 0, // This will prevent the view from expanding
                       marginTop: 5,
                       paddingTop: 5,
                       borderTop: "1px solid black",
                       width: "100%",
                       height: "15px",
                   }}
               >
                   <Text style={{ textAlign: "center" }}>
                       Ceysson & Bénétière - shipping@ceysson.com - +33 4 77 33 28 93 - +33 6 84 40 99 92
                   </Text>
               </View>
           </View>
           
                )}
              </View>
            ))}
          </View>): <Text>Nothing</Text>}
        </Page>
      ))}

    </Document>
  );
};

export default ArtworksLabelsPDF;
